import React from 'react'
import { Transition as ReactTransition, TransitionGroup } from 'react-transition-group'

/*entering: {
  // transition: `clip-path ${timeout}ms ease-in-out, transform ${timeout / 4}ms ease-in-out ${3 * (timeout / 4)}ms`,
  // transform: 'scale(1.01)',
  // clipPath : 'inset(0 0 0 100%)'
},
entered: {
  // transition: `clip-path ${timeout}ms ease-in-out, transform ${timeout / 4}ms ease-in-out ${3 * (timeout / 4)}ms`,
  // transformOrigin: '50vw 50vh',
  // transform: 'scale(1)',
  // clipPath : 'inset(0 0 0 0%)',
  // opacity: 1
},
exiting: {
  // transition: `opacity ${timeout}ms ease-in-out`,
  // opacity: 1
},*/

const timeout = 500
/*
const getTransitionStyles = {
  entering: {
    // transition: `transform ${timeout}ms ease-in-out, filter ${timeout}ms ease-in-out`,
    // transformOrigin: '50vw 50vh',
    // transform: 'translateX(-75px)',
    filter: 'opacity(0)',
    position: `absolute`,
  },
  entered: {
    transition: `transform ${timeout}ms ease-in-out, filter ${timeout}ms ease-in-out`,
    // transform: 'translateX(0%)',
    filter: 'opacity(1)',
  },
  exiting: {
    transition: `transform ${timeout}ms ease-in-out, filter ${timeout}ms ease-in-out`,
    // transform: 'translateX(+100px)',
    filter: 'opacity(0)',
  },
}
*/

const getTransitionStyles = {
  entering: {
    position: 'absolute',
    opacity: 0,
    // transform: `translateY(${window.scrollY}px)`
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 0
  },
}

class Transition extends React.PureComponent {
/*
  render() {
    return this.props.children
  }
*/

    render() {
      const { children, location } = this.props

      return (
        <TransitionGroup>
          <ReactTransition
            // enter={true}
            // exit={false}
            // appear={false}
            key={location.pathname}
            timeout={{
              enter: timeout,
              exit: timeout,
            }}
            onEnter={() => {
              document.documentElement.style.scrollBehavior = 'auto';
              document.body.scrollIntoView({
                behavior: 'auto',
                inline: 'start'
              })
            }}
          >
            {status => (
              <div
                id={'page-transition-wrapper'}
                data-location={location.pathname}
                className={`page-transition-status-${status}`}
                style={{
                  ...getTransitionStyles[status],
                }}
              >
                {children}
              </div>
            )}
          </ReactTransition>
        </TransitionGroup>
      )
    }
}

export default Transition
