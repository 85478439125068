import React from 'react'
import SeoHelmet from './seoHelmet'
import { convertNonBreakingSpace } from '../utilities'

const PageTitle = ({ title, seoTitle, ...otherProps }) => {
  const seoT = seoTitle ? seoTitle : `${title} | Narative.sk`

  return (
    <>
      <SeoHelmet
        { ...otherProps }
        title={convertNonBreakingSpace(seoT)}
      />
        <h1 {...otherProps}  dangerouslySetInnerHTML={{ __html: title }}></h1>
    </>
  )
}



export default PageTitle
