export const getSlug = (title, prefix) => {
  if (prefix === 'en') {
    prefix = null
  }
  if (title && title.length > 0) {
    const slug = title
      .normalize('NFD')
      .trim()
      .replace(/\s+/g, '-')
      .replace(/\./g, '')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
    if (prefix != null && prefix.length > 0) {
      return `${prefix}/${slug}`
    }
    return slug
  }
  return ''
}