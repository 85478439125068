import React, { Component } from 'react'

import { TranslatedLink } from '../TranslatedLink';
import LinkBtn from '../linkBtn/LinkBtn'
import { getTranslateFunction } from '../../utilities'
import './ContactUs.scss';
import HoverBtn from '../HoverBtn';

class ContaktUs extends Component {


  render() {
    const t = getTranslateFunction('sk')
    const { LeftSideLink, LeftSideSubTitle, LeftSideTitle, linkRight, rightSecondLink, blockClassName, leftClassName, rightClassName } = this.props;

    return (
      <section id='contact-us' className={"bottom-block " + blockClassName}>
        <div className="d-lg-flex">
          <div className={"col-lg-6 left " + leftClassName}>
            <div className="d-lg-flex">
              <div className="col-xl-6 col-lg-7 ml-auto">
                <h3>{LeftSideTitle === 'project' ? t('CaseStudyDetail.NextCategoryTitle') : t('References.MoreProjects.Title')}</h3>
                <h2>{LeftSideSubTitle}</h2>
                <HoverBtn color="silver" className="arrow">
                  <TranslatedLink to={LeftSideLink}>{t('References.MoreProjects.OpenBtn')}</TranslatedLink>
                </HoverBtn>
              </div>
            </div>
            <div className="background-element" />
          </div>
          <div className={"col-lg-6 right " + rightClassName}>
            <div className="d-md-flex">
              <div className="col-12">
                <h3>{t('References.Contact.Subtitle')}</h3>
                <h2>{t('References.Contact.Title')}</h2>
                <div className="bottom-links">
                  <HoverBtn>
                    <div><TranslatedLink to={linkRight}>{t('References.Contact.ContactUs')}</TranslatedLink></div>
                  </HoverBtn>
                  <LinkBtn color={'blue'} size={1}>
                    <TranslatedLink to={rightSecondLink}>{t('References.Contact.FillOutForm')}</TranslatedLink>
                  </LinkBtn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default ContaktUs

