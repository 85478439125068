import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import HoverBtn from '../../HoverBtn'
import './Modal.scss'

class Modal extends Component {

  constructor(props, ref) {
    super(props)

    this.state = {
      showModal: false,
      show: false
    }
  }
  toggleModal = () => {
    this.setState({ show: !this.state.show })
  };
  showModal = () => {
    document.body.style.overflow = "hidden"
    this.setState({ show: true })
    let that = this
    setTimeout(() => {
      that.setState({ showModal: true })
    }, 0);
  };
  closeModal = () => {
    document.body.style.overflow = "auto"
    this.setState({ showModal: false })
    let that = this
    setTimeout(() => {
      that.setState({ show: false })
    }, 500);
  };
  onSubmit = () => {
    this.props.onSubmitHandle()
    this.closeModal()
  }


  render() {
    const { title, closeBtn, submitBtn, showHeader = true, size, children, className } = this.props
    return (
      <>
        {this.state.show && (
          <div>
            <div className={`modal-backdrop fade ${this.state.showModal ? 'show' : ''}`} onClick={this.closeModal}></div>
            <div onClick={this.closeModal} className={`${className} modal fade ${this.state.showModal ? 'show' : ''}`}>
              <div className={`modal-dialog ${size ? 'modal-' + size : ''}`} onClick={(e) => e.stopPropagation()}>
                <div className="modal-content">

                  {/* header */}
                  {showHeader && (
                    <div className="modal-header">
                      <div className="modal-title h4">{title}</div>
                      <button onClick={this.closeModal} type="button" className="btn-close" aria-label="Close"></button>
                    </div>
                  )}

                  {/* body */}
                  <div className="modal-body">{children}</div>

                  {/* footer */}
                  <div className="modal-footer">
                    <div className="cp" onClick={this.closeModal}>{closeBtn}</div>
                    <HoverBtn className="ml-3" handleClick={this.onSubmit}>
                      <div>{submitBtn}</div>
                    </HoverBtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        }
      </>
    );
  }
};

Modal.defaultProps = {
  closeBtn: 'Cancel',
  submitBtn: 'Save Changes'
};

Modal.propTypes = {
  title: PropTypes.string,
  closeBtn: PropTypes.string,
  submitBtn: PropTypes.string,
  showHeader: PropTypes.bool,
  size: PropTypes.string,
}

export default Modal;