import React from 'react'
import Recaptcha from 'react-recaptcha'

function useForceUpdate() {
  const [, forceUpdate] = React.useState();


  return React.useCallback(() => {
    forceUpdate(s => !s);
  }, []);
}

// create a variable to store the component instance
let recaptchaInstance, captchaPromiseResolve, captchaPromiseReject, isLoaded = false, forceUpdateCb;

// manually trigger reCAPTCHA execution
export const verifyCaptcha = async () => {
  return new Promise((resolve, reject) => {
    captchaPromiseResolve = resolve;
    captchaPromiseReject = reject;
    if (recaptchaInstance != null) {
      recaptchaInstance.execute();
    } else {
      console.error('No ReCaptcha instance!');
    }
  })
};

// executed once the captcha has been verified
// can be used to post forms, redirect, etc.
export const verifyCallback = response => {
  if (captchaPromiseResolve == null || captchaPromiseReject == null) return;
  if (response) {
    captchaPromiseResolve(response);
  } else {
    captchaPromiseReject();
  }
  recaptchaInstance.reset();
};

export const expiredCallback = () => {
};

function loaded() {
  isLoaded = true;
}

if (typeof window !== 'undefined') {
  window.captchaOnLoadCallback = () => {
    if (forceUpdateCb) forceUpdateCb();
  }
}



const Captcha = () => {
  forceUpdateCb = useForceUpdate();

  return !isLoaded ? (
    <Recaptcha
      className={'captcha-position'}
      render="explicit"
      onloadCallback={loaded}
      ref={e => recaptchaInstance = e}
      sitekey="6Lczu7AUAAAAAAUNm3HapN34EoNFm3peCQUThg5n"
      size="invisible"
      expiredCallback={expiredCallback}
      verifyCallback={verifyCallback}
    />
  ) : <></>
}

export default Captcha