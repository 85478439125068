import React, { Component } from 'react'
import * as PropTypes from 'prop-types'


class HoverBtn extends Component {
  constructor(props, context) {
    super(props, context)
    this.ref = React.createRef()
    this.handleMouseIn = this.handleMouseIn.bind(this)
    this.handleMouseOut = this.handleMouseOut.bind(this)
    this.onClick = this.onClick.bind(this)
    
    this.state = {
        height: 0,
        hover: false,
      }
      
  }

  handleMouseIn() {
    this.setState({
      hover: true
    })
  }

  handleMouseOut(){
    this.setState({
      hover: false
    })
  }

  onClick(e) {
    if (this.ref) {
      const el = this.ref.current.querySelector('a')
      if (el) {
      return  el.click()
      }
    }

    this.props.handleClick(e)
  }

  componentDidMount() {
    if (this.ref.current != null) {
      let height = this.ref.current.getElementsByTagName('button')[0].getBoundingClientRect().height;
      this.setState({
        height: height
      })
    }
  }

  render() {
    const { children, className, id, color, textColor } = this.props
      return (
        <div
          id={id}
          ref={this.ref}
          className={`${className} btn-rotated`}
          onMouseOver={this.handleMouseIn}
          onMouseOut={this.handleMouseOut}
          onClick={(e) => { this.onClick(e) }}
        >
          <div className='inner'>
            <button
              className={`btn btn-hover ${color} ${textColor} ${this.state.hover ? 'hover' : ''}`}>
              <div className='hidden-text'>
                { children }
              </div>
            </button>
            <div className={`text ${color} ${textColor}`}>{ children }</div>
          </div>
        </div>
      )
    }
}
HoverBtn.propTypes = {
  color: PropTypes.oneOf(['red', 'blue', 'silver']),
  textColor: PropTypes.oneOf(['black']),
  className: PropTypes.string,
  id: PropTypes.string,
  handleClick: PropTypes.func
}

HoverBtn.defaultProps = {
  color: 'red',
  className: '',
  id: ''
}


export default HoverBtn
