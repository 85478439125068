import React, { Component } from 'react'
import { TranslatedLink } from './../TranslatedLink'
import { getCategoryDisplayName, getTranslateFunction, scrollIntoView } from '../../utilities'
import { getSlug } from '../../getSlug'
import './CaseStudies.scss'

/* const setHash = (hash) => {
  if (typeof window !== 'undefined' && hash.length > 0) {
    if (window.history.pushState) {
      window.history.pushState(null, null, `#${hash}`)
    } else {
      window.location.hash = `#${hash}`
    }
  }
}
 */
let lastActiveCategoryName = null
let currentPage = null

class CaseStudiesSideMenuGhost extends Component {

  static setCurrentPage(page) {
    currentPage = page;
  }

  static setActivePage(lang, pageName, categories, useRawCategoryNames, activeCategoryName, onCategoryClick) {
    if (!(currentPage === pageName || currentPage == null)) return;
    if (typeof window !== 'undefined' && window.CASE_STUDIES_FORCE_UPDATE_CB) {
      window.CASE_STUDIES_FORCE_UPDATE_CB(categories, activeCategoryName, useRawCategoryNames)
    }

    if (typeof document === 'undefined') {
      return
    }

    const el = document.getElementById(`our-work-ghost-menu-${pageName}`)
    const elLabel = document.querySelector(`#our-work-ghost-menu-${pageName} a.our-work-menu-top-level-item`)

    if (el != null) {
      if (!elLabel.classList.contains('active')) {
        const items = document.querySelectorAll('.our-work-menu-top-level-item')
        items.forEach((el) => {
          el.classList.remove('active')
        })

        elLabel.classList.add('active')

        const subCategoryEl = document.getElementById(`our-work-ghost-sub-menu-${pageName}`)

        subCategoryEl.style.height = (categories.length * 39) + 'px'

        if (subCategoryEl != null && !subCategoryEl.hasChildNodes()) {
          categories.forEach((category) => {
            const categoryDisplayName = useRawCategoryNames ? category : getCategoryDisplayName(category, lang)

            const listItemEl = document.createElement('li')
            const linkEl = document.createElement('a')
            listItemEl.appendChild(linkEl)

            const href = getSlug(category === 'corporate' ? 'firemne' : category);
            linkEl.setAttribute('href', `#${href}`)



            linkEl.onclick = (e) => {
              e.preventDefault()
              setTimeout(() => scrollIntoView(document.getElementById(`${href}`), false, null), 0)
              // setHash(href);
              onCategoryClick(category === 'corporate' ? 'firemne' : category)
            }
            linkEl.innerText = categoryDisplayName

            subCategoryEl.appendChild(listItemEl)
          })
        }
      }

      const hash = getSlug(activeCategoryName)

      if (activeCategoryName !== lastActiveCategoryName) {
        lastActiveCategoryName = activeCategoryName
      }

      document.querySelectorAll('.our-work-ghost-sub-menu a.active').forEach((e) => {
        e.classList.remove('active')
      })

      const activeEl = el.querySelector(`a[href="#${hash}"]`)
      if (activeEl != null) {
        activeEl.classList.add('active')
      } else {
        document.querySelectorAll('.our-work-ghost-sub-menu li:first-child a').forEach((e) => {
          e.classList.add('active')
        })
      }
    } else {
      console.error('Invalid pageName: ', pageName)
    }

    const ghostMenuEl = document.getElementById('our-work-ghost-menu')
    const menuEl = document.getElementById('case-studies-menu-reference')
    if (ghostMenuEl != null && menuEl != null) {
      const newHeight = ghostMenuEl.offsetHeight
      menuEl.style.height = newHeight + 'px'
      // ghostMenuEl.style.transform = `translateY(${Math.round((newHeight / 2) * -1)}px)`
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false
  }

  render() {
    const { lang } = this.props

    const t = getTranslateFunction(lang)

    return (
      <div
        className={'sidebar-menu'}
        id={'our-work-ghost-menu'}
        ref={this.listElement}
      >
        <ul>
          <li id={'our-work-ghost-menu-featured'}>
            <TranslatedLink
              className={'our-work-menu-top-level-item'}
              to="/en/our-works/featured">
              {t('References.Menu.Featured')}
            </TranslatedLink>
            <ul
              className={'our-work-ghost-sub-menu'}
              id={'our-work-ghost-sub-menu-featured'}>
            </ul>
          </li>
          <li id={'our-work-ghost-menu-websites'}>
            <TranslatedLink
              className={'our-work-menu-top-level-item'}
              to="/en/our-works/websites">
              {t('References.Menu.Webpages')}
            </TranslatedLink>
            <ul
              className={'our-work-ghost-sub-menu'}
              id={'our-work-ghost-sub-menu-websites'}>
            </ul>
          </li>
          <li id={'our-work-ghost-menu-applications'}>
            <TranslatedLink
              className={'our-work-menu-top-level-item'}
              to="/en/our-works/applications">
              {t('References.Menu.Applications')}
            </TranslatedLink>
            <ul
              className={'our-work-ghost-sub-menu'}
              id={'our-work-ghost-sub-menu-applications'}>
            </ul>
          </li>
          <li id={'our-work-ghost-menu-graphics'}>
            <TranslatedLink
              className={'our-work-menu-top-level-item'}
              to="/en/our-works/graphics">
              {t('References.Menu.Graphic')}
            </TranslatedLink>
            <ul
              className={'our-work-ghost-sub-menu'}
              id={'our-work-ghost-sub-menu-graphics'}>
            </ul>
          </li>
        </ul>
      </div>
    )
  }
}

export default CaseStudiesSideMenuGhost