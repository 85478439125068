/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import imgFw from '../images/nara_logo_720.webp'
import imgBw from '../images/nara_logo_720_r.webp'


const Logo = ({ isCollapsed }) => {
  isCollapsed = false
  const [show, onShow] = useState(false);

  setTimeout(() => {
    onShow(true)
  }, 1);

  return (
    <>
     {/*  <img
        style={{ height: 0 }}
        alt={'ghost-image'}
        src={imgFw}/>
      <img
        style={{ height: 0 }}
        alt={'ghost-image'}
        src={imgBw}/> */}
      {show &&
        <img
          width={'151px'}
          height={'46px'}
          alt={'Narative.sk logo'}
          /* className={`hvr-bob ${isCollapsed ? 'collapsed' : ''} finished `} */
            className={`${isCollapsed ? 'collapsed' : ''} finished sharped`}
            //src={imgBw}
          src={isCollapsed ? imgFw : imgBw}
          // onMouseEnter={() => setIsHovered(true)}
          // onMouseLeave={() => setIsHovered(false)}
        />
      }
    </>)
}

Logo.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
}

export default Logo
