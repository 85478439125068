import React, { Component } from 'react'
import { getCategoryDisplayName, getTranslateFunction, matchPath, translatedNavigate, scrollIntoView } from '../utilities'
import Select from 'react-select'
import { globalHistory } from '@reach/router'
import { getSlug } from '../getSlug'

class MobileMenuCaseStudies extends Component {
  constructor(props) {
    super(props)

    this.updateCategoriesFromGlobalVar()

    const { lang } = this.props
    const t = getTranslateFunction(lang)
    this.state = {
      selectedPagesOption: null,
      pagesOptions: [
        { value: '/en/our-works/featured', label: t('References.Menu.Featured') },
        { value: '/en/our-works/websites', label: t('References.Menu.Webpages') },
        { value: '/en/our-works/applications', label: t('References.Menu.Applications') },
        { value: '/en/our-works/graphics', label: t('References.Menu.Graphic') },
      ],
      categoriesOptions: [],
    }
    this.shouldUpdate = true
    this.handlePagesChange = this.handlePagesChange.bind(this)
    this.updateCategoriesFromGlobalVar = this.updateCategoriesFromGlobalVar.bind(this)
    this.handleCategoryChange = this.handleCategoryChange.bind(this)
    if (typeof window !== 'undefined') {
      window.CASE_STUDIES_FORCE_UPDATE_CB = this.updateCategoriesFromGlobalVar.bind(this)
    }
  }

  handlePagesChange(value) {
    translatedNavigate(value.value, this.props.lang)
    this.setState({
      selectedPagesOption: value,
    })
    if (this.state.categoriesOptions[0] != null) {
      this.setState({
        categoriesActive: this.state.categoriesOptions[0],
      })
    }
  }

  handleCategoryChange(value) {
    if (typeof window != 'undefined') {
      const category = value.value;
      const href = getSlug(category === 'corporate' ? 'firemne' : category);

      const selectedOption = this.state.pagesOptions.find((page) => matchPath(this.props.path, page.value, true))
      const offsetTop = selectedOption.value === '/en/our-works/featured' ? -50 : 1;

      //Nie je presny na 1. scrollTo,
      setTimeout(() => scrollIntoView(document.getElementById(`${href}`), false, 'start', offsetTop), 0)
      setTimeout(() => scrollIntoView(document.getElementById(`${href}`), false, 'start', offsetTop), 300)
      setTimeout(() => scrollIntoView(document.getElementById(`${href}`), false, 'start', offsetTop), 600)
      // window.location.hash = '#' + value.value
    }
  }

  updateCategoriesFromGlobalVar(categories, activeCategoryName, useRawCategoryNames) {
    if (categories == null) {
      return
    }

    const options = categories.map((categoryName) => {
      categoryName = categoryName === 'corporate' ? 'firemne' : categoryName
      return {
        value: useRawCategoryNames ? getSlug(categoryName) : categoryName,
        label: useRawCategoryNames ? categoryName : getCategoryDisplayName(categoryName, this.props.lang),
      }
    })

    const activeCategory = { value: activeCategoryName, label: useRawCategoryNames ? activeCategoryName : getCategoryDisplayName(activeCategoryName, this.props.lang) }

    this.setState({
      categoriesOptions: options,
      categoriesActive: activeCategory.value != null ? activeCategory : null,
    })
  }

  shouldBeVisible() {
    return [
      '/en/our-works',
      '/en/our-works/featured',
      '/en/our-works/websites',
      '/en/our-works/applications',
      '/en/our-works/graphics'].some((path) => matchPath(globalHistory.location.pathname, path))
  }

  render() {
    const { categoriesActive } = this.state
    const { lang, sticky } = this.props
    const t = getTranslateFunction(lang)

    const selectedPageOption = this.state.pagesOptions.find((page) => matchPath(this.props.path, page.value, true))
    const isVisible = this.shouldBeVisible()

    const isApp = selectedPageOption != null && selectedPageOption.value === '/en/our-works/applications' ? 'app' : ''
    return (
      <div
        className={`rwd-filter blog-filter MobileMenu bg-unset  MobileMenuAboutUs  MobileMenuCaseStudies stick ${isVisible ? '' : 'hide'} ${isApp}`}
      >
        <div className={'col'}>
          <div className="d-flex">
            <div className="col-6">
              <div className="left">
                <Select
                  className="MobileMenuSelect"
                  classNamePrefix={'MobileMenuSelect'}
                  options={this.state.pagesOptions}
                  isSearchable={false}
                  placeholder={t('OurServices.SectionSelect')}
                  value={selectedPageOption}
                  onChange={this.handlePagesChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#f6f6f6',
                      primary: 'transparent',
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-6 sub-category">
              <div className="right">
                <Select
                  className="MobileMenuSelect"
                  classNamePrefix={'MobileMenuSelect'}
                  options={this.state.categoriesOptions}
                  isSearchable={false}
                  placeholder={t('OurServices.SectionSelect')}
                  value={categoriesActive}
                  onChange={this.handleCategoryChange}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#f6f6f6',
                      primary: 'transparent',
                    },
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default MobileMenuCaseStudies