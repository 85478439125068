import React, { PureComponent  } from 'react'
import * as PropTypes from 'prop-types'
import './LinkBtn.scss'
import { degrees_to_radians, mapRange } from '../../utilities'

class LinkBtn extends PureComponent  {
  constructor(props, context) {
    super(props, context)
    this.ref = React.createRef()


    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onClick = this.onClick.bind(this)
    this.state = {
      loaded: false,
      isMousePositionSet: false,
      hover: false,
      mousePosition: {
        x: 0, y: 0,
      },
      size: null,
      mousePositionPercentage: {
        x: 0, y: 0,
      },
      correction: 0,
      hideUnderline: true,
      backgroundStyle: {}
    }
    this.wasMousePositionsetbefore = false
  }

  onMouseMove(event) {
    if (this.ref == null || window.innerWidth < 768) {
      return
    }
    const rect = this.ref.current.getBoundingClientRect()

    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    if (Math.abs(this.state.mousePosition.x - x) > 1 || Math.abs(this.state.mousePosition.y - y) > 1) {
      this.setState({
        hover: this.state.shouldHover,
        isMousePositionSet: true,
        mousePosition: { x, y },
        size: { x: rect.width, y: rect.height },
        mousePositionPercentage: {
          x: mapRange(x, 0, rect.width, 0, 1000),
          y: mapRange(y, 0, rect.height, 0, 1000),
        },
        correction: rect.y % 1
      })
    }
    this.setBackground(true)
  }

  onMouseEnter(event) {
    if (window.innerWidth < 768) {
      return
    }

    this.setState({
      shouldHover: true,
    })
    this.setBackground(true)
  }

  onMouseLeave(event) {
    if (window.innerWidth < 768) {
      return
    }

    this.setState({
      shouldHover: false,
      hover: false,
    })
    this.setBackground(false)
  }

    onClick(e) {
        if (this.ref) {
                const el = this.ref.current.querySelector('a')
            if (el) {
                el.click()
            }
        }
        this.props.handleClick()
    }
  setBackground(hover) {
      const { noUnderline } = this.props;
    let rectSize = this.state.size

    if (rectSize == null) {
      if (this.ref.current != null) {
        const rect = this.ref.current.getBoundingClientRect();
        rectSize = { x: rect.x, y: rect.y };
      } else {
        rectSize = { x: 0, y: 0 }
      }
    }


    const MAX_OFFSET = 10 // %
    const xOffset = mapRange(
      this.state.mousePosition.x,
      rectSize.x / 2,
      rectSize.x,
      0,
      MAX_OFFSET)
    const yOffset = mapRange(
      this.state.mousePosition.y,
      rectSize.y / 2,
      rectSize.y,
      0,
      MAX_OFFSET)

    const angleA = degrees_to_radians(30) // Toto meni sklon (48 je skoln ako v logu)
    const angleB = degrees_to_radians(90)
    const angleC = degrees_to_radians(180) - (angleA + angleB)

    const sideA = rectSize.y
    const sideB = (sideA * (Math.sin(angleA) / Math.sin(angleC)))
    const parallelogram_offset = sideB

    let x1 = 0
    let y1 = 0
    let x2 = rectSize.x + parallelogram_offset
    let y2 = 0
    let x3 = rectSize.x
    let y3 = rectSize.y
    let x4 = -parallelogram_offset
    let y4 = rectSize.y

    x1 = x1 + (rectSize.x * 1.5) + xOffset
    y1 = y1 + (rectSize.y * 1.5) + yOffset
    x2 = x2 + (rectSize.x * 1.5) + xOffset
    y2 = y2 + (rectSize.y * 1.5) + yOffset
    x3 = x3 + (rectSize.x * 1.5) + xOffset
    y3 = y3 + (rectSize.y * 1.5) + yOffset
    x4 = x4 + (rectSize.x * 1.5) + xOffset
    y4 = y4 + (rectSize.y * 1.5) + yOffset

    x1 = `${x1}px`
    y1 = `${y1}px`
    x2 = `${x2}px`
    y2 = `${y2}px`
    x3 = `${x3}px`
    y3 = `${y3}px`
    x4 = `${x4}px`
    y4 = `${y4}px`

    const rSizeX = 25
    const rSizeY = 1
    // TL
    const rx1 = `${0 + (rectSize.x * 1.5)}px`
    const ry1 = `${(rectSize.y - rSizeY) + (rectSize.y * 1.5)}px`
    // TR
    const rx2 = `${rSizeX + (rectSize.x * 1.5)}px`
    const ry2 = `${(rectSize.y - rSizeY) + (rectSize.y * 1.5)}px`
    // BR
    const rx3 = `${rSizeX + (rectSize.x * 1.5)}px`
    const ry3 = `${rectSize.y + (rectSize.y * 1.5)}px`
    // BL
    const rx4 = `${0 + (rectSize.x * 1.5)}px`
    const ry4 = `${rectSize.y + (rectSize.y * 1.5)}px`

    const px = `${(rectSize.x * 1.5) + this.state.mousePosition.x}px`
    const py = `${(rectSize.y * 1.5) + this.state.mousePosition.y}px`

    let backgroundStyle = hover ? {
      clipPath: `polygon(${x1} ${y1}, ${x2} ${y2}, ${x3} ${y3}, ${x4} ${y4})`,
      WebkitClipPath: `polygon(${x1} ${y1}, ${x2} ${y2}, ${x3} ${y3}, ${x4} ${y4})`,
    } : noUnderline ? {
      clipPath: `polygon(${px} ${py}, ${px} ${py}, ${px} ${py}, ${px} ${py})`,
      WebkitClipPath: `polygon(${px} ${py}, ${px} ${py}, ${px} ${py}, ${px} ${py})`,
    } : {
      clipPath: `polygon(${rx1} ${ry1}, ${rx2} ${ry2}, ${rx3} ${ry3}, ${rx4} ${ry4})`,
      WebkitClipPath: `polygon(${rx1} ${ry1}, ${rx2} ${ry2}, ${rx3} ${ry3}, ${rx4} ${ry4})`,
    }

    this.setState({
      backgroundStyle: { ...backgroundStyle, transform: `translateY(${-this.state.correction}px)` },
      loaded: true
    })
  }

  componentDidMount() {
    if (this.ref.current != null) {
      // TODO: slow dizajn loading
      setTimeout(() => {
        this.setData()
      }, 500);
      setTimeout(() => {
        this.setData()
      }, 1500);
      setTimeout(() => {
        this.setData()
      }, 3000);

    }
  }

  setData() {
    const target = this.ref.current
    if (target) {
      const rect = this.ref.current.getBoundingClientRect()
      this.setState({
        size: { x: rect.width, y: rect.height },
        correction: rect.y % 1,
        hideUnderline: false
      }, () => {
        this.setBackground()
      })
    }
  }


  render() {
    const { children, alignment, color, size, dropdown } = this.props

    return (
      <div
        onMouseMove={this.onMouseMove}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onClick}
        ref={this.ref}
        className={`LinkBtn ${color} ${alignment} ${this.state.hover ? 'hover' : ''} ${this.props.className} ${this.state.loaded ? 'loaded' : ''}`}
        style={{ fontSize: `${size}rem` }}
      >
        <div className='d-flex align-items-center'>
          {children}
          {dropdown &&
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" className='ml-lg-2'>
              <path d="M7.00023 8.21309L13.0102 2.20309L11.5972 0.788086L7.00023 5.38809L2.40423 0.788086L0.990234 2.20209L7.00023 8.21309Z" fill="#7F8D93"/>
            </svg>
          }
        </div>

        <div className={'invert'} style={{ ...this.state.backgroundStyle, display: this.state.hideUnderline ? 'none' : undefined }}>
          {children}
          {dropdown &&
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" className='ml-lg-2'>
              <path d="M7.00023 8.21309L13.0102 2.20309L11.5972 0.788086L7.00023 5.38809L2.40423 0.788086L0.990234 2.20209L7.00023 8.21309Z" fill="white"/>
            </svg>
          }
        </div>
      </div>
    )
  }
}

LinkBtn.propTypes = {
  alignment: PropTypes.oneOf(['left', 'center', 'right', '']),
  color: PropTypes.oneOf(['red', 'silver', 'blue', 'black']),
  size: PropTypes.number,
  noUnderline: PropTypes.bool,
  handleClick: PropTypes.func
}

LinkBtn.defaultProps = {
  align: 'left',
  color: 'red',
  size: 1.24,
  className: '',
  alignment: '',
    dropdown: false, 
   handleClick: () => {}, 
}

export default LinkBtn
