import React from 'react'
const SwitchInput = (props) => {

    const { defaultValue, children, name, disabled = false, onChange } = props;
    
    const [ value, setValue ] = React.useState(defaultValue);

    const valueChanged = (e) => {
        onChange(!value)
        setValue(!value)
    }

    return (
        <>
        <div className={`custom-switch ${disabled ? "disabled" : ''}`}>
            <label className="switch mr-3">
                <input disabled={disabled} type="checkbox" value={ value } name={ name } id={ name } onChange={valueChanged} checked={ value }/>
                <span className="slider round"></span>
            </label> 
            <div className="pt-1">{ children }</div>
        </div>
        </>
    );
};

export default SwitchInput