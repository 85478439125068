import React, { Component } from 'react'
import { TranslatedLink } from '../TranslatedLink'
import { getTranslateFunction } from '../../utilities'
import './SideMenu.scss'

const activeStyle = { fontFamily: 'silkamedium', color: '#014EFF' }

class SideMenu extends Component {
  constructor(props) {
    super(props)
    this.getOffset = this.getOffset.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.updateGhostElement = this.updateGhostElement.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.ref = React.createRef()
    this.state = {
        offset: 686.375,
        show: false
    }
      
    setTimeout(() => {
        this.setState({show: true})
    }, 1000)
  }

  getOffset() {
    if (this.ref.current == null) {
      return 0
    }
  
    return  this.ref.current.getBoundingClientRect().y
  }

  updateGhostElement(isSmooth) {
    const { isGhost } = this.props

    if (!isGhost && typeof document !== 'undefined') {
      const offset = this.getOffset()
      const el = document.getElementById('sideMenuWrapper')

      if (el == null) return;

      const transitionWrapperEl = document.getElementById('page-transition-wrapper')
      let isPageInTransition = true
      if (transitionWrapperEl != null) {
        isPageInTransition = !transitionWrapperEl.classList.contains('page-transition-status-entered')
      }
      if (!isSmooth) {
        el.classList.add('smooth')
      } else {
        el.classList.remove('smooth')
      }
      if (el != null && offset !== 0 && !isPageInTransition) {
        if (window.innerWidth > 991 && window.innerWidth < 1280 && offset > Math.round(window.innerHeight / 2)) {
          return  el.style.transform = `translateY(${Math.round(window.innerHeight / 2)}px)`
        }
        el.style.transform = `translateY(${Math.round(offset)}px)`
      }

    }
  }

  onScroll() {
    const offset = this.getOffset()
    this.setState({ offset: offset })
    this.updateGhostElement(true)
  }

  updateDimensions() {
    console.log('dddddddd')
  }

  componentDidMount() {
    const { isGhost } = this.props

    if (!isGhost) {
      document.addEventListener('scroll', this.onScroll, {passive: true})
      this.updateGhostElement()
    }

    setTimeout(() => {
      this.updateGhostElement()
    }, 600)
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, { passive: true })
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { lang, isGhost } = this.props

    /*setTimeout(() => {
      this.updateGhostElement()
    }, 200)*/

    // const pathname = globalHistory.location.pathname


    const t = getTranslateFunction(lang)

    // const isFirstItemActive = matchPath(pathname, '/en/about-us') || matchPath(pathname, '/en/about-us/our-services')
    return (
      <div className={'sidebar-menu ' + (isGhost ? 'ghost-menu' : 'sticky') + (this.state.show ? ' show' : '')} ref={this.ref} style={{ visibility: !isGhost ? 'hidden' : 'visible' }}>
        <ul>
          <li>
            <TranslatedLink to="/en/about-us" activeStyle={activeStyle} activeClassName={'active'} title-bak={t('OurServices.Menu')}>{t('OurServices.Menu')}</TranslatedLink>
          </li>
          <li>
            <TranslatedLink to="/en/about-us/clients" activeStyle={activeStyle} activeClassName={'active'} title-bak={t('OurWork.Menu')}>{t('OurWork.Menu')}</TranslatedLink>
          </li>
          <li>
            <TranslatedLink to="/en/about-us/how-we-work" activeStyle={activeStyle} activeClassName={'active'} title-bak={t('HowWeWork.Menu')}>{t('HowWeWork.Menu')}</TranslatedLink>
          </li>
          <li>
            <TranslatedLink to="/en/about-us/ux-principes" activeStyle={activeStyle} activeClassName={'active'} title-bak={t('UxPrincipes.Menu')}>{t('UxPrincipes.Menu')}</TranslatedLink>
          </li>
        </ul>
      </div>
    )
  }
}

export default SideMenu
