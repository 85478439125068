import React, { Component } from 'react'
import Layout from '../components/layout'
import Lightbox from 'react-images'
import { TranslatedLink } from '../components/TranslatedLink'
import { getCategorySlug, getTranslateFunction, slugFormat } from '../utilities'
import Img from 'gatsby-image'
import Tilt from 'react-tilt'
import { graphql } from 'gatsby'
import LinkBtn from '../components/linkBtn/LinkBtn'
import PageTitle from '../components/pageTitle'
import { useDrag } from 'react-use-gesture'
import HoverBtn from '../components/HoverBtn'
import ContaktUs from '../components/contactUs/ContactUs'
import Reveal from '../components/reveal'

/* eslint-disable no-alert, no-console */
export const shouldNavigateBack = {
  value: true,
}
/* eslint-enable no-alert, no-console */
const SwipeDetector = ({ onSwipeRight, onSwipeLeft, active }) => {
  const bind = useDrag((state) => {
    if (state.tap === false && state.dragging === false) {
      if (state.direction[0] > 0) {
        onSwipeRight()
      } else {
        onSwipeLeft()
      }
    }
  }, { domTarget: typeof window === 'undefined' ? undefined : window, filterTaps: false, axis: 'x', enabled: active })

  React.useEffect(bind, [bind])

  return (null)
}

class CaseStudy extends Component {
  constructor(props) {
    super(props)

    this.state = {
      galleryCollapsed: true,
      currentLightBoxItem: 1,
      lightBoxOpened: false,
    }

    this.screensWrapperRef = React.createRef()

    this.handleExpandGalleryToggle = this.handleExpandGalleryToggle.bind(this)
    this.openLightBox = this.openLightBox.bind(this)
    this.closeLightBox = this.closeLightBox.bind(this)
    this.goToNextLightBox = this.goToNextLightBox.bind(this)
    this.goToPrevLightBox = this.goToPrevLightBox.bind(this)
    this.getNumberOfImagesInRow = this.getNumberOfImagesInRow.bind(this)
    this.onResize = this.onResize.bind(this)

    const { data } = this.props
    const caseStudy = { ...data.service.frontmatter, html: data.service.html }

    const allOtherProjectsCaseStudies = [
      { category: 'corporate', projects: data.webpages_corporate.childMarkdownRemark.frontmatter.other_projects },
      { category: 'eshop', projects: data.webpages_eshop.childMarkdownRemark.frontmatter.other_projects },
      { category: 'portal', projects: data.webpages_portal.childMarkdownRemark.frontmatter.other_projects },
      { category: 'microsite', projects: data.webpages_microsite.childMarkdownRemark.frontmatter.other_projects },
      { category: 'crm', projects: data.webpages_crm.childMarkdownRemark.frontmatter.other_projects },
      { category: 'app', projects: data.applications_other_projects.childMarkdownRemark.frontmatter.other_projects },
      { category: 'logo_branding', projects: data.graphics_logo_branding_other_projects.childMarkdownRemark.frontmatter.other_projects },
      { category: 'digital_print', projects: data.graphics_digital_print_other_projects.childMarkdownRemark.frontmatter.other_projects },
    ];


    let nextCaseStudy = null
    allOtherProjectsCaseStudies.some((category) => {
      const categoryProjects = category.projects;

      const currentCaseStudy = categoryProjects.find((cs) => {
        let sourceFileSlug = slugFormat(this.props.data.service.frontmatter.title).toLowerCase()
          .trim()
          .replace(/-/g, '_')
          .replace(/ /g, '_')
          .replace(/&/g, '')
          .replace(/__/g, '_')
          .replace(/\./g, '')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')

        if (/[0-9]+-[0-9]+-[0-9]+_/.test(sourceFileSlug)) {
          sourceFileSlug = sourceFileSlug.replace(/[0-9]+-[0-9]+-[0-9]+_/, '')
        }

        const slug = cs.case_study
          .toLowerCase()
          .trim()
          .replace(/\./g, '')
          .replace(/ /g, '_')
          .replace(/&/g, '')
          .replace(/__/g, '_')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')

        return slug === sourceFileSlug
      })

      if (currentCaseStudy == null) {
        return false
      }
      const currentCaseStudyIndex = categoryProjects.indexOf(currentCaseStudy)
      nextCaseStudy = {
        ...categoryProjects[(currentCaseStudyIndex + 1) % categoryProjects.length],
        category: category.category
      }
      return true
    })

    if (nextCaseStudy != null) {
      const nextCaseStudyCategory = nextCaseStudy.category != null ? nextCaseStudy.category : 'app';
      this.nextCaseStudySlug = `${caseStudy.language === 'en' ? '/en/our-works/' : '/nase-prace/'}${getCategorySlug(nextCaseStudyCategory, caseStudy.language)}/${slugFormat(nextCaseStudy.case_study)}`
      this.nextCaseStudyTitle = nextCaseStudy.title != null ? nextCaseStudy.title : ''
      this.allOtherProjectsCaseStudies = allOtherProjectsCaseStudies

    } else {
      console.error('No nextCaseStudy on page:', this.props.data.service.frontmatter.title)
    }
  }

  getNumberOfImagesInRow() {
    if (this.screensWrapperRef.current === null) return
    const el = this.screensWrapperRef.current
    return Math.round(el.offsetWidth / el.querySelector(':first-child').offsetWidth)
  }

  onResize() {
    this.forceUpdate()
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.onResize, {passive: true})
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize, {passive: true})
  }

  getCaseStudySlugByTitle(title) {
    if (this.allOtherProjectsCaseStudies == null) return
    for (const categoryProjects of this.allOtherProjectsCaseStudies) {
      const currentCaseStudy = categoryProjects.projects.find((cs) => {
        return cs.case_study === title
      })

      if (currentCaseStudy != null) {
        const caseStudyCategory = currentCaseStudy.category != null ? currentCaseStudy.category : 'app'
        const lang = 'sk' //TODO
        return `${lang === 'en' ? '/en/our-works/' : '/nase-prace/'}${getCategorySlug(caseStudyCategory, lang)}/${slugFormat(currentCaseStudy.case_study)}`
      }
    }
  }

  handleExpandGalleryToggle() {
    this.setState((prevState) => {
      if (prevState.galleryCollapsed === false) {
        setTimeout(() => {
          document.body.scrollTop = 0 // For Safari
          document.scrollingElement.scrollTop = 0
        }, 0)
      }
      return {
        galleryCollapsed: !prevState.galleryCollapsed,
      }
    })
  }

  openLightBox(i) {
    if (typeof document !== 'undefined') {
      setTimeout(() => {
        try {
          document.querySelector('#lightboxBackdrop').parentElement.parentElement.parentElement.classList.add('lightboxBackdrop-wrapper')
        } catch (e) {
          console.error(e)
        }
      }, 0)
    }
    this.setState({
      currentLightBoxItem: i,
      lightBoxOpened: true,
    })

    document.body.style.backgroundPosition = 'calc(100% - 12px) 0'

    setTimeout(() => {
      if (typeof document !== 'undefined') {
        const el = document.getElementById('lightboxBackdrop')
        if (el) {
          el.classList.add('fade')
        }
      }
    }, 1)
  }

  closeLightBox() {
    if (typeof document !== 'undefined') {
      const el = document.getElementById('lightboxBackdrop')
      if (el) {
        el.classList.remove('fade')
      }
    }
    setTimeout(() => {
      document.body.style.backgroundPosition = '100% 0'

      this.setState({
        lightBoxOpened: false,
      })
    }, 1)
  }

  goToNextLightBox() {
    document.getElementById('lightboxBackdrop').getElementsByTagName('img')[0].classList.add('fade-out')
    const newIndex = this.state.currentLightBoxItem + 1
    const len = this.props.data.service.frontmatter.screens.length
    setTimeout(() => {

      this.setState({
        currentLightBoxItem: newIndex < len ? newIndex : len - 1,
      })
      document.getElementById('lightboxBackdrop').getElementsByTagName('img')[0].classList.remove('fade-out')
    }, 1000)
  }

  goToPrevLightBox() {
    document.getElementById('lightboxBackdrop').getElementsByTagName('img')[0].classList.add('fade-out')
    const newIndex = this.state.currentLightBoxItem - 1
    //  const len = this.props.data.service.frontmatter.screens.length
    setTimeout(() => {
      this.setState({
        currentLightBoxItem: newIndex > 0 ? newIndex : 0,
      })
      document.getElementById('lightboxBackdrop').getElementsByTagName('img')[0].classList.remove('fade-out')
    }, 1000)
  }

  onGalleryClick(e) {
    if (!e.target.closest('img') && !e.target.closest('svg')) {
      console.log(!e.target.closest('svg'))
    }
  }

  render() {
    const { data } = this.props
    const caseStudy = { ...data.service.frontmatter, html: data.service.html }

    const t = getTranslateFunction(caseStudy.language)

    let collapseToggleElement = this.state.galleryCollapsed ? (
      <LinkBtn  handleClick={this.handleExpandGalleryToggle}>
        <span>{t('CaseStudyDetail.GalleryShowMore')}</span>
      </LinkBtn>
    ) : (
      <LinkBtn color={'silver'}  handleClick={this.handleExpandGalleryToggle}>
        <span>{t('CaseStudyDetail.GalleryShowLess')}</span>
      </LinkBtn>
    )

    if (caseStudy.screens?.length <= 6) {
      collapseToggleElement = null
    }

    const numberOfImagesInRow = this.getNumberOfImagesInRow()
    const imagesMinHeight = numberOfImagesInRow === 2 ? 940 : 324 * 6

    const useIframeView = caseStudy.banner_url?.trim().length > 0;
    return (
      <Layout className="detail-reference-page" showBackButton={true} lang={caseStudy.language}>
        <SwipeDetector onSwipeRight={this.goToPrevLightBox.bind(this)} onSwipeLeft={this.goToNextLightBox.bind(this)} active={this.state.lightBoxOpened}/>
        <section className="detail-reference margin-top">
          <div className="container">
            <div className="row">
              <div className="col-md-6 left">
                <div className="sticky-cont">
                  <div>
                    <PageTitle title={caseStudy.title} description={ caseStudy.description } url={this.props.location.href}/>
                
                    {caseStudy.link == null
                      ? <p className="description">{caseStudy.description}</p>
                      : <a className="description link" href={caseStudy.link} target={'_blank '}>{caseStudy.description}</a>
                    }
                    <p className="description-smaller" dangerouslySetInnerHTML={{ __html: caseStudy.html }}/>
                    <h3>{t('CaseStudyDetail.Subtitle')}</h3>
                    <ul className="works">
                      {caseStudy.what_we_done?.map((item, i) => {
                     /*    let cs = null

                        if (item.case_study_url && item.case_study_url.length > 0) {
                          cs = item.case_study_url
                        }

                        if (item.case_study != null) {
                          cs = this.getCaseStudySlugByTitle(item.case_study)
                        } */
                        return <li key={i}>
                          <img className="image" src="/images/svg/icon_tag.svg" alt={item.text} />
                          <span>{item.text}</span>
                        </li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            {/*   <div className={`gallery-cont ${this.state.lightBoxOpened ? 'opened' : ''}`} onClick={this.onGalleryClick}>
                <Fade autoplay={false}>
                    {caseStudy.screens.filter((i) => {
                        return i.href != null
                      }).map((fadeImage, index) => (
                      <div className="each-fade" key={index}>
                        <div className="image-container">
                          <img src={fadeImage.href.childImageSharp.fluid.src} />
                        </div>
                      </div>
                    ))}
                  </Fade>
              </div> */}

              {useIframeView
                ? <div className="right banner">
                  <iframe title="bannerPreview" src={caseStudy.banner_url} width={304} height={604} scrolling={false} id={'bannerPreview'}/>
                </div>
                : <div className="col-md-6 right dr-gallery-wrap">
                  <div
                    className={`row mb-4 dr-gallery ${this.state.galleryCollapsed ? '' : 'open'}`}
                    ref={this.screensWrapperRef}
                    style={{
                      height: this.state.galleryCollapsed ?
                        ((Math.min((Math.ceil(caseStudy.screens.length / numberOfImagesInRow) * 324), imagesMinHeight)) + 50) :
                        ((Math.ceil(caseStudy.screens.length / numberOfImagesInRow) * 324) + 50),
                    }}>
                    
                    

                    <Lightbox
                      images={caseStudy.screens.filter((i) => {
                        return i.href != null
                      }).map((i, n) => {
                        return {
                          src: i.href.childImageSharp.fluid.srcWebp,
                          width: i.href.childImageSharp.fluid.presentationWidth,
                          alt: `${caseStudy.title} Screen #${n}`
                        }
                      })}
                      onClose={this.closeLightBox}
                      isOpen={this.state.lightBoxOpened}
                      onClickPrev={this.goToPrevLightBox}
                      onClickNext={this.goToNextLightBox}
                      preloadNextImage={false}
                      currentImage={this.state.currentLightBoxItem}
                      closeButtonTitle={'Zatvoriť'}
                      imageCountSeparator={' z '}
                      leftArrowTitle={'Predchádzajúce'}
                      rightArrowTitle={'Ďalšie'}
                      backdropClosesModal={true}
                      preventScroll={false}
                    />

                    {caseStudy.screens.map((screen, i) => {
                      let shouldRender = (i < 6 || !this.state.galleryCollapsed)
                      let shouldHide = i < 6
                      return (
                        <div className={'col-sm-6  mb-4 item ' + (shouldHide ? '' : (shouldRender ? 'visible' : 'hide'))}
                          key={i} onClick={() => {
                          this.openLightBox(i)
                        }}>
                          <Tilt
                            className={'tilt'}
                            options={{
                              max: 25,
                              glare: true,
                              'max-glare': 0.5,
                              reverse: true,
                              scale: 1.05,
                            }}>
                            {screen.href != null && <Img
                              style={{ height: 300, maxHeight: 300, maxWidth: 300 }}
                              fluid={screen.href.childImageSharp.fluid}
                              alt={screen.alt}
                              imgStyle={{ objectPosition: 'center top' }}
                            />}
                          </Tilt>
                        </div>
                      )
                    })
                    }
                  </div>
                  {collapseToggleElement}
                </div>
              }
            </div>
          </div>
            </section>
            
        <Reveal>
          <ContaktUs
            LeftSideTitle='project'
            LeftSideSubTitle={this.nextCaseStudyTitle}
            LeftSideLink={this.nextCaseStudySlug}
            linkRight="/en/contact"
            rightSecondLink="/en/brief"
          />
        </Reveal>
      </Layout>
    )
  }
}

export default CaseStudy

export const query = graphql`query ($slug: String! $category: String) {
    service: markdownRemark(fields: {slug: {eq: $slug}}) {
        frontmatter {
            language
            title
            description
            link
            banner_url
            what_we_done {
                text
                case_study
            }
            screens {
                href {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                            presentationWidth
                        }
                    }
                }
                alt
            }
        }
        html
    }
    featuredService: allMarkdownRemark(filter: {fields: {slug: {regex: "/(/case-studies/)/"}} frontmatter: {category: {eq: $category}}}) {
        edges {
            node {
                fields {slug}
                frontmatter {
                    category
                    title
                }
            }
        }
    }
    webpages_corporate: file(relativePath: {eq: "pages/sk/references/web_pages_corporate.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    url
                    show_in_references_slider
                    case_study
                }
            }
        }
    }
    webpages_eshop: file(relativePath: {eq: "pages/sk/references/web_pages_eshop.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    url
                    show_in_references_slider
                    case_study
                }
            }
        }
    }
    webpages_portal: file(relativePath: {eq: "pages/sk/references/web_pages_portal.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    url
                    show_in_references_slider
                    case_study
                }
            }
        }
    }
    webpages_microsite: file(relativePath: {eq: "pages/sk/references/web_pages_microsite.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    url
                    show_in_references_slider
                    case_study
                }
            }
        }
    }
    webpages_crm: file(relativePath: {eq: "pages/sk/references/web_pages_crm.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    url
                    show_in_references_slider
                    case_study
                }
            }
        }
    }
    applications_other_projects: file(relativePath: {eq: "pages/sk/references/applications.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    url
                    show_in_references_slider
                    case_study
                }
            }
        }
    }
    graphics_logo_branding_other_projects: file(relativePath: {eq: "pages/sk/references/graphic_logo_branding.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    url
                    show_in_references_slider
                    case_study
                }
            }
        }
    }
    graphics_digital_print_other_projects: file(relativePath: {eq: "pages/sk/references/graphic_digital_print.md"}) {
        childMarkdownRemark {
            frontmatter {
                other_projects {
                    title
                    url_override
                    url
                    show_in_references_slider
                    case_study
                }
            }
        }
    }
}
`

