import React, { Component } from 'react'
import { getTranslateFunction, matchPath } from '../utilities'
import Select from 'react-select'
import { globalHistory } from '@reach/router'

const defaultCategory = {
  value: null,
  label: 'Všetky články',
}

class MobileMenuBlog extends Component {
  constructor(props) {
    super(props)

    this.updateCategoriesFromGlobalVar()

    this.state = {
      selectedOption: defaultCategory,
      options: [defaultCategory],
    }
    this.handleChange = this.handleChange.bind(this)
    this.nextSection = this.nextSection.bind(this)
    this.updateCategoriesFromGlobalVar = this.updateCategoriesFromGlobalVar.bind(this)
  }

  handleChange(value) {
    this.setState({
      selectedOption: value,
    })
    if (typeof window !== 'undefined' && window.BLOG_CATEGORY_CLICK_HANDLER != null) {
      window.BLOG_CATEGORY_CLICK_HANDLER(value.value)
    }
  }

  updateCategoriesFromGlobalVar() {
    let categories = []
    if (typeof window !== 'undefined') {
      window.BLOG_FORCE_UPDATE_CB = this.updateCategoriesFromGlobalVar.bind(this)
      categories = window.BLOG_ALL_CATEGORIES
    }

    if (categories == null) {
      return
    }

    const options = categories.map((categoryName) => {
      return { value: categoryName, label: categoryName }
    })

    this.setState({
      options: [defaultCategory, ...options],
    })
  }

  nextSection(back) {
    const currentIndex = this.state.selectedOption != null ? this.state.options.indexOf(this.state.selectedOption) : 0

    let nextIndex = 0

    if (back) {
      nextIndex = (currentIndex + 1) % this.state.options.length
    } else {
      nextIndex = ((currentIndex - 1) >= 0) ? (currentIndex - 1) : this.state.options.length - 1
    }

    const nextOption = this.state.options[nextIndex]

    if (typeof window !== 'undefined' && window.BLOG_CATEGORY_CLICK_HANDLER != null) {
      window.BLOG_CATEGORY_CLICK_HANDLER(nextOption.value )
    }

    this.setState({
      selectedOption: nextOption,
    })
  }

  shouldBeVisible() {
    return matchPath(globalHistory.location.pathname, '/blog')
  }

  render() {
    const { selectedOption } = this.state
    const { lang, sticky } = this.props
    const t = getTranslateFunction(lang)

    const isVisible = this.shouldBeVisible()
    return (
      <div
        className={`rwd-filter blog-filter MobileMenu MobileMenuAboutUs bg-unset ${(sticky ? '' : 'stick')} ${isVisible ? '' : 'hide'}`}
      >
        <div className="d-flex">
          <div className="col">
            <div className="blog-filter-wrap">
              <span className="blog-filter-prev" onClick={() => {
                this.nextSection(false)
              }}><i className="la la-arrow-left"></i></span>
              <Select
                className="MobileMenuSelect"
                classNamePrefix={'MobileMenuSelect'}
                options={this.state.options}
                isSearchable={false}
                placeholder={t('OurServices.SectionSelect')}
                value={selectedOption}
                onChange={this.handleChange}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f6f6f6',
                    primary: 'transparent',
                  },
                })}
              />
              <span className="blog-filter-next" onClick={() => {
                this.nextSection(true)
              }}><i className="la la-arrow-right"></i></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default MobileMenuBlog