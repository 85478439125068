import React, { useState } from 'react'
import BackButton from '../components/backButton/backButton'
import { TranslatedLink } from '../components/TranslatedLink'
import Logo from '../components/logo'
import LinkBtn from '../components/linkBtn/LinkBtn'
import MultiTargetTranslatedLink from '../components/MultiTargetTranslatedLink'
import { Link } from 'gatsby'
import { getTranslatedSlug, getTranslateFunction, isBrowser } from '../utilities'
import useScrollPosition from '@react-hook/window-scroll'
//import { LangContext } from './index'
import MobileMenuAboutUs from '../components/MobileMenuAboutUs'
import MobileMenuBlog from '../components/MobileMenuBlog'
import MobileMenuCaseStudies from '../components/MobileMenuCaseStudies'
import MobileMenuCaseStudyPageShowcase from '../components/MobileMenuCaseStudyPageShowcase'
import MobileMenuServices from '../components/MobileMenuServices'
import SideMenu from '../components/sideMenu/side-menu'
import SideMenuServices from '../components/sideMenu/side-menu-services'
import CaseStudiesSideMenuGhost from '../components/case-studies/caseStudiesSideMenuGhost'
import { navigate } from 'gatsby';
import { globalHistory } from '@reach/router'
import TelephoneNumber from '../components/TelephoneNumber'
//import HoverBtn from '../components/HoverBtn'


const Navigation = ({ lang, location, showBackButton, isCaseStudy }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const scrollY = useScrollPosition(60 /*fps*/);
  const isScrolledTop = scrollY === 0;

  const t = getTranslateFunction(lang)
  const currentPath = location.pathname

  const path = currentPath.split('#')[0].replace(/\/$/, '')

  const isAboutUsMenuVisible = ['/en/about-us', '/o-nas',
    '/en/about-us/clients', '/o-nas/klienti',
    '/en/about-us/ux-principes', '/o-nas/ux-principy',
    '/en/about-us/how-we-work', '/o-nas/ako-pracujeme'].includes(path)

  const isServicesMenuVisible = ['/en/services', '/sluzby',
    '/en/services/programming', '/sluzby/programovanie',
    '/en/services/service', '/sluzby/servis',
    '/en/services/design', '/sluzby/dizajn'].includes(path)

  const isOurWorkMenuVisible = [
    '/en/our-works', '/nase-prace',
    '/en/our-works/featured', '/nase-prace/vybrane',
    '/en/our-works/websites', '/nase-prace/webstranky',
    '/en/our-works/applications', '/nase-prace/aplikacie',
    '/en/our-works/graphics', '/nase-prace/grafika'].includes(path)

  const isServiceDetail = [
   /*  '/tvorba-mobilnych-aplikacii',
    '/tvorba-webov',
    '/tvorba-mobilnych-aplikacii',
    '/eshop-na-mieru',
    '/ux-ui-dizajn',
    '/tvorba-loga',
    '/wodpress-a-woocommerce',
    '/tvorba-web-aplikacii',
    '/webdizajn',
    '/graficky-dizajn',
    '/branding', */
  ].includes(path)

  const programingLinks = {
    title: 'Programovanie',
    link: '/sluzby/programovanie',
    links: [
      { title: 'Tvorba eshopu', link: '/eshop-na-mieru' },
      { title: 'Tvorba webstránok', link: '/tvorba-webov' },
      { title: 'Tvorba webu vo WordPresse', link: '/wodpress-a-woocommerce' },
      { title: 'Vývoj webových aplikácií', link: '/tvorba-web-aplikacii' },
      { title: 'Vývoj mobilných aplikácií', link: '/tvorba-mobilnych-aplikacii' },
    ]
  }

  const dizajnLinks = {
    title: 'Dizajn',
    link: '/sluzby/dizajn',
    links: [
      { title: 'Web dizajn', link: '/webdizajn' },
      { title: 'UX & UI dizajn', link: '/ux-ui-dizajn' },
      { title: 'Tvorba loga', link: '/tvorba-loga' },
      { title: 'Branding', link: '/branding' },
      { title: 'Grafický dizajn', link: '/graficky-dizajn' },
    ]
  }
  const aboutUsLinks = {
    title: null,
    link: null,
    links: [
      { title: t('OurServices.Menu'), link: '/o-nas' },
      { title: t('OurWork.Menu'), link: '/o-nas/klienti' },
      { title: t('HowWeWork.Menu'), link: '/o-nas/ako-pracujeme' },
      { title: t('UxPrincipes.Menu'), link: '/o-nas/ux-principy' },
    ]
  }

  
  if (isBrowser() && document.body.classList.contains('show-btn-back')) {
    showBackButton = true;
    
  }
       
    showBackButton = showBackButton || (isServiceDetail ? false : showBackButton)  // Toto schova button len pri prvom nacitani stranky v detaile servisi

    if (isBrowser() && showBackButton === false) {
    document.body.classList.remove('show-btn-back')
  }

  const onNavMenuOpen = (value) => {
    if (value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    setIsMobileMenuOpen(value)
  }

  const isLogoCollapsed = isMobileMenuOpen === true || !(isScrolledTop && !showBackButton)
  return (
    <>

      <div id={'sideMenuWrapper'}>
        {isAboutUsMenuVisible && <SideMenu lang={lang} isGhost={true} />}
        {isServicesMenuVisible && <SideMenuServices lang={lang} isGhost={true} />}
        {isOurWorkMenuVisible && <CaseStudiesSideMenuGhost lang={lang} />}
      </div>
      <header className={`${(isScrolledTop && !isCaseStudy) ? '' : 'stick'}`}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5 fix-bg-mobile">
              <BackButton
                lang={lang}
                onClick={() => onNavMenuOpen(false)}
                pathname={location.pathname}
                isVisible={showBackButton}
              />
              <div
                id="desktop-logo"
                className={(isMobileMenuOpen ? 'menu-open' : 'menu-close')}
                onClick={() => {
                  if (['', '/', '/en', '/en/'].indexOf(currentPath) !== -1) {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                  }
                }}
              >
                <TranslatedLink to="/en" title-bak={'Narative'} onClick={() => onNavMenuOpen(false)}>
                  <Logo isCollapsed={isLogoCollapsed} />
                </TranslatedLink>
              </div>
            </div>
            <div className={'col-lg-7 text-right' + (isMobileMenuOpen ? ' open' : '')}>
              <span className="mobile-Menu-open-menu" id="rwd-open-menu" onClick={() => onNavMenuOpen(true)}>
                <i className="la la-bars"></i>
              </span>
              <span className="mobile-Menu-close-menu" id="rwd-close-menu" onClick={() => onNavMenuOpen(false)}>
                <i className="la la-close"></i>
              </span>
              <nav className={'main-menu ' + (isMobileMenuOpen ? 'open' : '')}>
                <ul>
                  <li className='order-lg-1'>
                    <LinkBtn color={isMobileMenuOpen ? 'black' : 'silver'} noUnderline={true}>
                      <MultiTargetTranslatedLink
                        onClick={() => onNavMenuOpen(false)}
                        activeStyle={{ color: '#014EFF' }}
                        targets={[
                          '/en/our-works',
                          '/en/our-works/featured',
                          '/en/our-works/websites',
                          '/en/our-works/applications',
                          '/en/our-works/graphics',
                        ]}
                        to="/en/our-works/featured"
                        title-bak={t('Navigation.ourWork')}
                      >
                        {t('Navigation.ourWork')}
                      </MultiTargetTranslatedLink>
                    </LinkBtn>
                  </li>
                  <li className='order-lg-2'>
                    {!isMobileMenuOpen
                      ? <DesktopDropdown leftLinks={programingLinks} rightLinks={dizajnLinks} lang onClick={() => onNavMenuOpen(false)} btnName={t('Navigation.services')} btnLink='/en/services' />
                      :
                      <>
                        <MobileDropdown lang onNavMenuOpen={onNavMenuOpen} links={[programingLinks,dizajnLinks]} btnName={t('Navigation.services')} btnLink='/en/services' />
                      </>
                    }
                  </li>
                  <li className='order-lg-4'>
                    <div className='mobile-subtitle'>
                      <LinkBtn color={isMobileMenuOpen ? 'black' : 'silver'} noUnderline={true}>
                        <MultiTargetTranslatedLink
                          onClick={() => onNavMenuOpen(false)}
                          activeStyle={{ color: '#014EFF' }}
                          targets={['/en/services/service']}
                          to="/en/services/service"
                          title-bak='Servis'>Servis</MultiTargetTranslatedLink>
                      </LinkBtn>
                    </div>
                  </li>
                  <li className='order-lg-3'>
                    {!isMobileMenuOpen
                      ? <DesktopDropdown leftLinks={aboutUsLinks} lang onClick={() => onNavMenuOpen(false)} btnName={t('Navigation.aboutUs')} btnLink='/en/about-us' />
                      :
                      <>
                        <MobileDropdown lang onNavMenuOpen={onNavMenuOpen} links={[aboutUsLinks]} btnName={t('Navigation.aboutUs')} btnLink='/en/about-us' />
                      </>
                    }
                  </li>
                  <li className='order-lg-5'>
                    <LinkBtn color={isMobileMenuOpen ? 'black' : 'silver'} noUnderline={true}>
                      <MultiTargetTranslatedLink
                        onClick={() => onNavMenuOpen(false)}
                        activeStyle={{ color: '#014EFF' }}
                        targets={['/en/blog']}
                        to="/en/blog"
                        title-bak={t('Navigation.blog')}>{t('Navigation.blog')}
                      </MultiTargetTranslatedLink>
                    </LinkBtn>
                  </li>
                  <li className='order-lg-6'>
                    <LinkBtn color={isMobileMenuOpen ? 'black' : 'silver'} noUnderline={true}>
                      <MultiTargetTranslatedLink
                        onClick={() => onNavMenuOpen(false)}
                        activeStyle={{ color: '#014EFF' }}
                        targets={['/en/brief']}
                        to="/en/brief"
                        title-bak={t('Navigation.contact')}>{t('Navigation.brief')}</MultiTargetTranslatedLink>
                    </LinkBtn>
                  </li>
                  <li className='order-lg-7'>
                    <LinkBtn noUnderline={true}>
                      <TranslatedLink onClick={() => onNavMenuOpen(false)} to="/en/contact">{t('Navigation.contact')}</TranslatedLink>
                    </LinkBtn>
                  </li>
                  <li className="flag" style={{ display: lang === 'sk' ? 'none' : '' }}>
                    {
                      lang === 'sk' ? (
                        <Link to={getTranslatedSlug(currentPath)} title="English" onClick={() => onNavMenuOpen(false)}><img src="/images/svg/flag_us.svg" alt="Narative" /></Link>
                      ) : (
                        <Link to={getTranslatedSlug(currentPath)} title="English" onClick={() => onNavMenuOpen(false)}><img src="/images/svg/flag_sk.svg" alt="Narative" /></Link>
                      )
                    }
                  </li>
                  {/*  {isMobileMenuOpen &&
                    <>
                     <li className='follow-us'>
                        <span>Sledujte nás</span>
                        <div className={'d-flex socialite-icons'}>
                          <a className="d-flex" href='https://www.facebook.com/narativemedia/' target='_blank' rel='noopener noreferrer'>
                            <svg viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg>
                          </a>
                          <a className="d-flex" href='https://www.instagram.com/narative.sk/' target='_blank' rel='noopener noreferrer'>
                            <svg svg viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"></path></svg>
                          </a>
                        </div>
                      </li>
                    </>
                  } */}
                </ul>
                {isMobileMenuOpen &&
                  <div className='d-grid justify-content-center d-md-none'>
                    <a className='link-secondary mb-2' href='mailto:info@narative.sk'>info@narative.sk</a>
                    <TelephoneNumber />
                  </div>
                }
              </nav>
            </div>
          </div>
        </div>
      </header>
      <MobileMenuAboutUs lang={lang} sticky={isScrolledTop} />
      <MobileMenuBlog lang={lang} sticky={isScrolledTop} />
      <MobileMenuCaseStudies lang={lang} sticky={isScrolledTop} path={currentPath} />
      <MobileMenuCaseStudyPageShowcase lang={lang} sticky={isScrolledTop} path={currentPath} />
      <MobileMenuServices lang={lang} sticky={isScrolledTop} path={currentPath} />
    </>
  )
}


const DesktopDropdown = ({ leftLinks, rightLinks = null, btnName, btnLink }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const onNavLinkClick = (link) => {
 //   navigate(link);
      setDropdownOpen(false)
  }

  const targets = () => {
    let targets = leftLinks.links.map(item => item.link)
    if (rightLinks) {
      targets = [...targets, ...rightLinks.links.map(item => item.link)]
    }
    return targets
  }

  return (
    <div
      className='nav-dropdown'
      onMouseEnter={() => setDropdownOpen(true)}
      onClick={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
    >
      <LinkBtn
        dropdown={true}
        color={'silver'}
        noUnderline={true}
      >
        <MultiTargetTranslatedLink
          strict={true}
          activeStyle={{ color: '#014EFF' }}
          targets={targets()}
          to={btnLink}
          title-bak={btnName}
        >
          {btnName}
        </MultiTargetTranslatedLink>
      </LinkBtn>
 
      <div className={`menu-dropdown ${dropdownOpen ? 'show' : 'hide'}`}>
        <div className='menu-dropdown-cont'>
          
          <div className='menu-header row m-0'>
            {leftLinks.link &&
              <div onClick={() => onNavLinkClick(leftLinks.link)} className={`col-6`}>
                <MultiTargetTranslatedLink
                  strict={true}
                  activeStyle={{ color: '#014EFF' }}
                  targets={[leftLinks.link]}
                  to={leftLinks.link}
                  title-bak={leftLinks.title}
                >
                  {leftLinks.title}
                </MultiTargetTranslatedLink>
              
              </div>
            }
            {rightLinks &&
              <div onClick={() => onNavLinkClick(rightLinks.link)} className={`col-6`}>
                <MultiTargetTranslatedLink
                  strict={true}
                  activeStyle={{ color: '#014EFF' }}
                  targets={[rightLinks.link]}
                  to={rightLinks.link}
                  title-bak={rightLinks.title}
                >
                  {rightLinks.title}
                </MultiTargetTranslatedLink>
              </div>
            }
          </div>
          <div className={`dropdown-content d-flex`}>
            <ul className={`col-6 ${ !leftLinks.link ? "no-parent" : "" }`} >
              {leftLinks.links.map((link, key) => (
                <li onClick={() => onNavLinkClick(link.link)} >
                  <MultiTargetTranslatedLink
                    strict={true}
                    activeStyle={{ color: '#014EFF' }}
                    targets={[link.link]}
                    to={link.link}
                    title-bak={link.title}
                  >
                    {link.title}
                  </MultiTargetTranslatedLink>
                </li>
              ))}
      
            </ul>
            {rightLinks &&
              <ul className={`col-6 ${ !rightLinks.link ? "no-parent" : "" }`} >
                {rightLinks.links.map((link, key) => (
                  <li onClick={() => onNavLinkClick(link.link)}>
                    <MultiTargetTranslatedLink
                      strict={true}
                      activeStyle={{ color: '#014EFF' }}
                      targets={[link.link]}
                      to={link.link}
                      title-bak={link.title}
                    >
                      {link.title}
                    </MultiTargetTranslatedLink>
                  </li>
                ))}
              </ul>
            }
          </div>
        </div>
      </div>
    </div>
  )
}


const MobileDropdown = ({ onNavMenuOpen, btnName, btnLink, links }) => {
  
  const [dropdownOpen, setDropdownOpen] = useState(() => {
    if (links[0].title) {
      return true
    }

    const isActive = links.filter(item => {
      return item.links.filter(childItem => globalHistory.location.pathname.startsWith(childItem.link)).length > 0
    }).length > 0
    
    if(isActive) return true
    

    return false
  })


  const onNavLinkClick = () => {
    //navigate(btnLink);
    onNavMenuOpen(false)
  }

  const isActive = (url) => {
    return globalHistory.location.pathname.startsWith(url)
  }
  
  return (
    <div className='mobile-dropdown'>
      <div className={`dropdown-title ${dropdownOpen ? 'open' : ''}`}>
        <div className={`dropdown-title-text`} onClick={(e) => onNavLinkClick()}  >
          {<MultiTargetTranslatedLink
              strict={true}
              activeStyle={{ color: '#014EFF' }}
              targets={[btnLink]}
              to={btnLink}
              title-bak={btnName}
            >
              {btnName}
          </MultiTargetTranslatedLink>}
        </div>
        {!links[0].title &&
          <div className='dropdown-subtitle-arrow' onClick={() => setDropdownOpen(!dropdownOpen)}>
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" className='ml-lg-2' >
              <path d="M7.00023 8.21309L13.0102 2.20309L11.5972 0.788086L7.00023 5.38809L2.40423 0.788086L0.990234 2.20209L7.00023 8.21309Z" fill="black" />
            </svg>
          </div>
        }
      </div>
      {dropdownOpen &&
        <>
          {links.map((group, key) => (
            <MobileDropdownItems
              onNavMenuOpen={onNavMenuOpen}
              lang
              title={group.title}
              url={group.link}
              links={group.links}
            />
          ))}
        </>
      }
    </div>
  )
}



const MobileDropdownItems = ({ title, url, links, onNavMenuOpen }) => {
  const [dropdownOpen, setDropdownOpen] = useState(() => {
    const isActive = links.filter(item => globalHistory.location.pathname.startsWith(item.link)).length > 0

    if (!title || !url || isActive) {
      return true
    }
    return false
  })

  const onNavLinkClick = () => {
    navigate(url);
    onNavMenuOpen(false)
  }

  const isActive = (link) => {
    return globalHistory.location.pathname.startsWith(link)
  }

  return (
    <div className={`dropdown-items-cont ${dropdownOpen ? 'open' : ''}`}>
      {title &&
        <div className={`dropdown-subtitle`}>
          <div className={`dropdown-subtitle-link ${isActive(url) ? 'active' : ''}`} onClick={() => onNavLinkClick()}>
            {title}
          </div>
          <div className='dropdown-subtitle-arrow' onClick={() => setDropdownOpen(!dropdownOpen)}>
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" className='ml-lg-2' >
              <path d="M7.00023 8.21309L13.0102 2.20309L11.5972 0.788086L7.00023 5.38809L2.40423 0.788086L0.990234 2.20209L7.00023 8.21309Z" fill="black" />
            </svg>
          </div>
        </div>
      }
      {dropdownOpen &&
        <ul className={`dropdown-items`}>
          {links.map((link, key) => (
            <li key={key} onClick={() => onNavMenuOpen(false)}>
              {<MultiTargetTranslatedLink
                strict={true}
                activeStyle={{ color: '#014EFF' }}
                targets={[link.link]}
                to={link.link}
                title-bak={link.title}
              >
                {link.title}
              </MultiTargetTranslatedLink>}
            </li>
          ))}

        </ul>
      }
    </div>
  )
}


export default Navigation

