import React, { Component } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import PropTypes from 'prop-types'

class Reveal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isRevealed: false,
    }
    this.onChange = this.onChange.bind(this)
  }

  onChange(value) {
    if (!this.state.isRevealed && value) {
      this.setState({
        isRevealed: true,
      })
    }
  }

/*  componentDidMount() {
    this.forceUpdate()
  }*/

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.state.isRevealed || nextState.isRevealed
  }

/*
  render() {
    const { children, placeholderHeight, ...otherProps } = this.props

    return <div {...otherProps} className={`revealWrapper revealed`}>
      {children}
    </div>
  }
*/

    render() {
      const { children, placeholderHeight, otherClassName, ...otherProps } = this.props

      let style = {}
      if (!this.state.isRevealed) {
        style = { minHeight: placeholderHeight != null ? placeholderHeight : '50vh' }
      }
      return (
        <VisibilitySensor
          onChange={this.onChange}
          partialVisibility={true}
          active={!this.state.isRevealed}
          intervalDelay={500}
        >
          <div {...otherProps} className={`revealWrapper ${this.state.isRevealed ? 'revealed' : ''} ${otherClassName}`} style={style}>
            {children}
          </div>
        </VisibilitySensor>
      )
    }
}

Reveal.propTypes = {
  placeholderHeight: PropTypes.number,
}

export default Reveal
