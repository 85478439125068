import Helmet from 'react-helmet'
import React from 'react'

export const PageHelmet = () => (
  <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <title>Narative.sk - Prémiový web design, UX, branding a aplikácie</title>

    <meta property="og:title" content="Narative"/>
    <meta property="og:type" content="page"/>
    <meta property="og:image" content="http://narative.sk/images/thumbnail.jpg"/>
    <meta property="og:site_name" content="Narative - Prémiový web design, UX, aplikácie & branding"/>
    <meta name="author" content="narative.sk"/>

    <link rel="apple-touch-icon" sizes="57x57" href="/images/fav/apple-icon-57x57.png"/>
    <link rel="apple-touch-icon" sizes="60x60" href="/images/fav/apple-icon-60x60.png"/>
    <link rel="apple-touch-icon" sizes="72x72" href="/images/fav/apple-icon-72x72.png"/>
    <link rel="apple-touch-icon" sizes="76x76" href="/images/fav/apple-icon-76x76.png"/>
    <link rel="apple-touch-icon" sizes="114x114" href="/images/fav/apple-icon-114x114.png"/>
    <link rel="apple-touch-icon" sizes="120x120" href="/images/fav/apple-icon-120x120.png"/>
    <link rel="apple-touch-icon" sizes="144x144" href="/images/fav/apple-icon-144x144.png"/>
    <link rel="apple-touch-icon" sizes="152x152" href="/images/fav/apple-icon-152x152.png"/>
    <link rel="apple-touch-icon" sizes="180x180" href="/images/fav/apple-icon-180x180.png"/>
    <link rel="icon" type="image/png" sizes="192x192" href="/images/fav/android-icon-192x192.png"/>
    <link rel="icon" type="image/png" sizes="32x32" href="/images/fav/favicon-32x32.png"/>
    <link rel="icon" type="image/png" sizes="96x96" href="/images/fav/favicon-96x96.png"/>
    <link rel="icon" type="image/png" sizes="16x16" href="/images/fav/favicon-16x16.png"/>
    <meta name="msapplication-TileColor" content="#ffffff"/>
    <meta name="msapplication-TileImage" content="/images/fav/ms-icon-144x144.png" />
    <meta name='viewport' content='width=device-width, maximum-scale=1.0, minimum-scale=1.0,initial-scale=1' />


    <link rel="manifest" href="/images/fav/site.webmanifest"/>
    <link rel="mask-icon" href="/images/fav/safari-pinned-tab.svg" color="#5bbad5"/>
    <link rel="shortcut icon" href="/images/fav/favicon.ico"/>
    <meta name="msapplication-config" content="/images/fav/browserconfig.xml"/>
    <meta name="theme-color" content="#eb1260"/>

    <meta name="description" content={'Potrebujete eshop na mieru, alebo responzívny web? Ponúkame vám jedinečné a originálne riešenia s dizajnom webu presne podľa vašich predstáv.'} key={3}/>
    <meta property="og:description" content="Potrebujete eshop na mieru, alebo responzívny web? Ponúkame vám jedinečné a originálne riešenia s dizajnom webu presne podľa vašich predstáv."/>
  </Helmet>
)
