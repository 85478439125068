import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import "../../src/styles/mixins.scss"


class Layout extends Component {
  constructor(props) {
    super(props)
    this.ref = null
    this.setRef = (el) => {
      this.ref = el
    }
  }


  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.ref != null) {
      const el = this.ref.closest(`[data-location="${window.location.pathname}"]`)
      if (el != null) {
        return true
      }
    }
    return false
  }
  
    setBackButton() {
    const { showBackButton } = this.props

    if (typeof document !== 'undefined') {
      const el = document.body
      showBackButton ? el.classList.add('show-btn-back') : el.classList.remove('show-btn-back')
      // el.classList.add('show-btn-back');
    }
  }

  componentDidMount() {
    this.setBackButton();
  }

    render() {
    this.setBackButton();
    const { children, showBackButton, hideFooter, ...otherPorps } = this.props

    return (
      <div ref={this.setRef} {...otherPorps} id="layout">
    
        {children}
      </div>
    )
  }
}

Layout.propTypes = {
    showBackButton: PropTypes.bool,
    hideFooter: PropTypes.bool,
}

export default Layout
