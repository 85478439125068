import { LangContext } from './../layouts/index'
import React from 'react'
import { Link } from 'gatsby'
import { getTranslatedSlug } from '../utilities'

const getTranslatedLink = (lang, slug) => {

  if (lang === 'sk') {
    const translatedSlug = getTranslatedSlug(slug)
    return translatedSlug === '#' ? slug : translatedSlug
  } else {
    return slug
  }
}


export const TranslatedLink = (props) => {
  const { children, to, useNativeLink, ...otherProps } = props

  const langProp = props.lang
  return (
    <LangContext.Consumer>
      {(lang) => {
        const language = (langProp != null ? langProp : lang)

        if (useNativeLink) {
          return (<a href={getTranslatedLink(language, to)} {...otherProps}>{children}</a>)
        } else {
          return (<Link to={getTranslatedLink(language, to)} {...otherProps}>{children}</Link>)
        }
      }}
    </LangContext.Consumer>
  )
}
