import React, { Component } from 'react'
import { TranslatedLink } from '../TranslatedLink'
import { getTranslateFunction, matchPath } from '../../utilities'
import { globalHistory } from '@reach/router'
import './SideMenu.scss'

const activeStyle = { fontFamily: 'silkamedium', color: '#014EFF' }

class SideMenuServices extends Component {
  constructor(props) {
    super(props)
    this.getOffset = this.getOffset.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.updateGhostElement = this.updateGhostElement.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
    this.ref = React.createRef()
    this.state = {
        offset: 686.375,
        show: false
    }
      
    setTimeout(() => {
        this.setState({show: true})
    }, 1000)
  }

  getOffset() {
    if (this.ref.current == null) {
      return 0
    }
    return this.ref.current.getBoundingClientRect().y
  }

  updateGhostElement(isSmooth) {
    const { isGhost } = this.props

    if (!isGhost && typeof document !== 'undefined') {
      
      const offset = this.getOffset()
      const el = document.getElementById('sideMenuWrapper')

      if (el == null) return

      const transitionWrapperEl = document.getElementById('page-transition-wrapper')
      let isPageInTransition = true
      if (transitionWrapperEl != null) {
        isPageInTransition = !transitionWrapperEl.classList.contains('page-transition-status-entered')
      }
      if (!isSmooth) {
        el.classList.add('smooth')
      } else {
        el.classList.remove('smooth')
      }
      if (el != null && offset !== 0 && !isPageInTransition) {
        if (window.innerWidth > 991 && window.innerWidth < 1280 && offset > Math.round(window.innerHeight / 2)) {
          return  el.style.transform = `translateY(${Math.round(window.innerHeight / 2)}px)`
        }
        el.style.transform = `translateY(${Math.round(offset)}px)`
      }
    }
  }

  onScroll() {
    const offset = this.getOffset()
    this.setState({ offset: offset })
    this.updateGhostElement(true)
  }

  updateDimensions() {
    this.updateGhostElement()
  }

  componentDidMount() {
    const { isGhost } = this.props

    if (!isGhost) {
      document.addEventListener('scroll', this.onScroll, {passive: true})
      this.updateGhostElement()

      window.addEventListener("resize", this.updateDimensions);
    }
  }

    componentDidUpdate() {
    const { isGhost } = this.props

    if (!isGhost) {
      setTimeout(() => {
        this.updateGhostElement()
      }, 1000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const { lang, isGhost } = this.props
    
    const pathname = globalHistory.location.pathname


    const t = getTranslateFunction(lang)
    
    const isFirstItemActive = matchPath(pathname, '/en/services') || matchPath(pathname, '/en/services/programming')
    
    return (
      <div className={'sidebar-menu ' + (isGhost ? 'ghost-menu' : 'sticky') + (this.state.show ? ' show' : '')} ref={this.ref} style={{ visibility: !isGhost ? 'hidden' : 'visible' }}>
        <ul>
          <li>
            <TranslatedLink to="/en/services/programming" className={isFirstItemActive ? 'active' : ''} style={isFirstItemActive ? activeStyle : {}}>{t('Services.Programming.Menu')}</TranslatedLink>
          </li>
          <li>
            <TranslatedLink to="/en/services/design" activeStyle={activeStyle} activeClassName={'active'}>{t('Services.Design.Menu')}</TranslatedLink>
          </li>
          <li>
            <TranslatedLink to="/en/services/service" activeStyle={activeStyle} activeClassName={'active'}>Servis</TranslatedLink>
          </li>
        </ul>
      </div>
    )
  }
}

export default SideMenuServices
