import React, { Component } from 'react'
import { TranslatedLink } from './TranslatedLink'
import { globalHistory } from '@reach/router'
import { getTranslatedSlug } from '../utilities'

class MultiTargetTranslatedLink extends Component {
  render() {
    const { targets, strict = false, children, activeStyle, ...other } = this.props


    let active = false
    if (targets != null) {
      active = targets.reduce((acc, e) => {
        return [...acc, getTranslatedSlug(e)]
      }, targets).some((url) => {
        if (strict) {
          return globalHistory.location.pathname === url
        }
        return globalHistory.location.pathname.startsWith(url)
      })
    }

    return (
      <TranslatedLink
        style={active ? activeStyle : null}
        className={active ? 'is-active' : ''}
        {...other}
      >
        {children}
      </TranslatedLink>
    )
  }
}

export default MultiTargetTranslatedLink