import React, { Component } from 'react'
import { getTranslateFunction, matchPath, translatedNavigate } from '../utilities'
import Select from 'react-select'
import { globalHistory } from '@reach/router'

class MobileMenuAboutUs extends Component {
  constructor(props) {
    super(props)
    const { lang } = this.props
    const t = getTranslateFunction(lang)
    this.options = [
      { value: '/en/about-us', label: t('OurServices.Menu') },
      { value: '/en/about-us/clients', label: t('OurWork.Menu') },
      { value: '/en/about-us/how-we-work', label: t('HowWeWork.Menu') },
      { value: '/en/about-us/ux-principes', label: t('UxPrincipes.Menu') },
    ]
    
    this.state = {
      selectedOption: this.findOption(),
    }
    this.handleChange = this.handleChange.bind(this)
    this.nextSection = this.nextSection.bind(this)
  }

  handleChange(value) {
    translatedNavigate(value.value, this.props.lang)
  }

  findOption() {
    return this.options.find((option) => matchPath(globalHistory.location.pathname, option.value)) || this.options[0]
  }

  setOption() {
    this.setState({
      selectedOption: this.findOption(),
    })
  }

  nextSection(back) {
    const currentIndex = this.state.selectedOption != null ? this.options.indexOf(this.state.selectedOption) : 0

    let nextIndex = 0

    if (back) {
      nextIndex = (currentIndex + 1) % this.options.length
    } else {
      nextIndex = ((currentIndex - 1) >= 0) ? (currentIndex - 1) : this.options.length - 1
    }

    const nextOption = this.options[nextIndex]
      translatedNavigate(nextOption.value, this.props.lang)
    }

  shouldBevisible() {
    return [
      '/en/about-us',
      '/en/about-us/ux-principes',
      '/en/about-us/clients',
      '/en/about-us/how-we-work']
      .some((path) => {
        return matchPath(globalHistory.location.pathname, path)
      })
  }

  componentWillMount() {
    this.unlisten = globalHistory.listen((location, action) => {
      this.setOption()
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { selectedOption } = this.state
    const { lang, sticky } = this.props
    const t = getTranslateFunction(lang)

    const isVisible = this.shouldBevisible()
    return (
      <div
        className={`rwd-filter blog-filter MobileMenu bg-unset MobileMenuAboutUs ${(sticky ? '' : 'stick')} ${isVisible ? '' : 'hide'}`}
      >
        <div className="d-flex">
          <div className="col">
            <div className="blog-filter-wrap">
              <span className="blog-filter-prev" onClick={() => {
                this.nextSection(false)
              }}><i className="la la-arrow-left"></i></span>
              <Select
                className="MobileMenuSelect"
                classNamePrefix={'MobileMenuSelect'}
                options={this.options}
                isSearchable={false}
                placeholder={t('OurServices.SectionSelect')}
                value={selectedOption}
                onChange={this.handleChange}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f6f6f6',
                    primary: 'transparent',
                  },
                })}
              />
              <span className="blog-filter-next" onClick={() => {
                this.nextSection(true)
              }}><i className="la la-arrow-right" /></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default MobileMenuAboutUs