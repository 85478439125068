import React, { Component } from 'react'
import { getTranslatedSlug, matchPath } from '../../utilities'
import { navigate } from 'gatsby'
import { shouldNavigateBack } from '../../templates/case-study'
import HoverBtn from '../HoverBtn'
import './BackButton.scss'

class BackButton extends Component {
  constructor(props) {
    super(props)
    this.onBackClickHandler = this.onBackClickHandler.bind(this)
  }

  translatedNavigate(url, hash) {
    const { lang } = this.props
    let path = lang === 'en' ? url : getTranslatedSlug(url)
    navigate(path, { state: { hash: hash } })
  }

  onBackClickHandler() {
    const directLinks = {
      '/eshop-na-mieru': '/sluzby/programovanie',
      '/tvorba-webov': '/sluzby/programovanie',
      '/wodpress-a-woocommerce': '/sluzby/programovanie',
      '/tvorba-web-aplikacii': '/sluzby/programovanie',
      '/tvorba-mobilnych-aplikacii': '/sluzby/programovanie',
      '/webdizajn': '/sluzby/dizajn',
      '/ux-ui-dizajn': '/sluzby/dizajn',
      '/tvorba-loga': '/sluzby/dizajn',
      '/branding': '/sluzby/dizajn',
      '/graficky-dizajn': '/sluzby/dizajn'
    }
    const url = this.props.pathname
    const link = directLinks[url]

    this.scrollTheLastPosition()

    if (link) {
      return navigate(link)
    }

  
    if (url.indexOf('/pripadove-studie') !== -1) {
      window.history.back()
    } else if (matchPath(url, '/en/our-works/featured', true)) {
      return this.translatedNavigate('/en/our-works/featured')
    } else if (matchPath(url, '/en/our-works/websites', true)) {
      return shouldNavigateBack.value ? window.history.back() : this.translatedNavigate('/en/our-works/websites')
    } else if (matchPath(url, '/en/our-works/applications', true)) {
      return shouldNavigateBack.value ? window.history.back() : this.translatedNavigate('/en/our-works/applications')
    } else if (matchPath(url, '/en/our-works/graphics', true)) {
      return shouldNavigateBack.value ? window.history.back() : this.translatedNavigate('/en/our-works/graphics')
    } else if (matchPath(url, '/en/our-works', true)) {
      return this.translatedNavigate('/en/our-works')
    } /* else if (matchPath(url, '/en/blog', true)) {
      return this.translatedNavigate('/en/blog')
    } */ else if (matchPath(url, '/en/about-us/our-services', true)) {
      return this.translatedNavigate('/en/about-us/our-services')
    } else {

      window.history.back()
    }
  }

  scrollTheLastPosition() {
    if (typeof window !== 'undefined') {
      const { position } = JSON.parse(localStorage.getItem("scroll_last_position"));
      setTimeout(() => {
        window.scrollTo({ top: position, behavior: 'smooth' })
      }, 500);
    }
  }

  backButtonText() {
    const url = this.props.pathname

    if (matchPath(url, '/en/eshop-na-mieru', true) ||
      matchPath(url, '/en/services/create-website', true) ||
      matchPath(url, '/en/services/design', true) ||
      matchPath(url, '/en/tvorba-mobilnych-aplikacii', true) ||
      matchPath(url, '/en/wodpress-a-woocommerce', true) ||
      matchPath(url, '/en/dessign', true) ||
      matchPath(url, '/en/ux-ui-dizajn', true) ||
      matchPath(url, '/en/tvorba-loga', true) ||
      matchPath(url, '/en/branding', true) ||
      matchPath(url, '/en/graficky-dizajn', true) ||
       matchPath(url, '/en/tvorba-web-aplikacii', true)) {
      return 'Služby'
    }
    return 'Späť'
  }

  render() {
    const isVisible = true;
    if (!isVisible) return (null)


    return (
      <HoverBtn
        id={'backbtn'}
        className="btn-back blue no-shadow"
        color="blue"
        handleClick={this.onBackClickHandler}
      >
        <div>
          <i className="la la-angle-left" /> { this.backButtonText() }
        </div>
      </HoverBtn>
    )
  }
}

export default BackButton