import React, { Component } from 'react'
import { getTranslateFunction } from '../utilities'

class MobileMenuCaseStudyPageShowcase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOption: null,
      options: [],
      isVisible: false,
    }
    this.handleChange = this.handleChange.bind(this)

    if (typeof window !== 'undefined') {
      window.CASE_STUDY_PAGE_SHOWCASE_MOBILE_MENU = this.updateCaseStudyPageShowcaseMobileMenu.bind(this)
    }
  }

  handleChange(value) {
    this.setState({
      selectedOption: value,
    })

    if (this.onChangeCallback != null) {
      const index = this.state.options.indexOf(value)
      this.onChangeCallback(index, false)
    }
  }

  updateCaseStudyPageShowcaseMobileMenu(categories, activeCategory, onChangeCallback, isMenuVisible) {
    this.onChangeCallback = onChangeCallback
    this.setState({
      selectedOption: activeCategory,
      options: categories,
      isVisible: isMenuVisible,
    })
  }

  nextSection(back) {
    const currentIndex = this.state.selectedOption != null ? this.state.options.indexOf(this.state.selectedOption) : 0

    let nextIndex = 0

    if (back) {
      nextIndex = (currentIndex + 1) % this.state.options.length
    } else {
      nextIndex = ((currentIndex - 1) >= 0) ? (currentIndex - 1) : this.state.options.length - 1
    }

    const nextOption = this.state.options[nextIndex]
    this.handleChange(nextOption)
  }

  render() {
    const { selectedOption } = this.state
    const { lang } = this.props
    const t = getTranslateFunction(lang) 

    const isVisible = this.state.isVisible

    return (
      <div
        className={`rwd-filter blog-filter MobileMenu bg-unset MobileMenuAboutUs cs stick ${isVisible ? '' : 'hide'}`}
      >
        <div className="d-flex">
          <div className="col">
            <div className="blog-filter-wrap">
              <span className="blog-filter-prev" onClick={() => {
                this.nextSection(false)
              }}
              ><i className="la la-arrow-left"></i></span>

              {selectedOption &&
                <div className="MobileMenuSelect d-flex align-items-center justify-content-center text-white">{selectedOption.value}</div>
              }
            {/*   <Select
                className="MobileMenuSelect"
                classNamePrefix={'MobileMenuSelect'}
                options={this.state.options}
                isSearchable={false}
                placeholder={t('OurServices.SectionSelect')}
                value={selectedOption}
                onChange={this.handleChange}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f6f6f6',
                    primary: 'transparent',
                  },
                })}
              /> */}
              <span className="blog-filter-next" onClick={() => {
                this.nextSection(true)
              }}><i className="la la-arrow-right"></i></span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default MobileMenuCaseStudyPageShowcase