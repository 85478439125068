const lang = {
  "404": {
    "BackBtn": "Back to the home page",
    "Img": "https://i.imgur.com/j51uHm1.gif",
    "Title": "The page you were looking for does not exist."
  },
  "Blog": {
    "AllArticles": "All articles",
    "MoreBtn": "Check out more",
    "SectionSelect": "Choose section",
    "Title": "We are writing about the world of design."
  },
  "BlogDetail": {
    "FacebookShare": "Share on Facebook",
    "MoreBtn": "Show more",
    "TwitterShare": "Share to Twitter"
  },
  "CaseStudyDetail": {
    "ContactSubtitle": "Did we make you interested?",
    "ContactTitle": "Contact us",
    "ContactUsBtn": "Write to us",
    "FillOutForm": "Fill in the brief",
    "GalleryShowLess": "Show less",
    "GalleryShowMore": "Show more",
    "NextCategoryOpenBtn": "Show",
    "NextCategoryTitle": "Next category",
    "Subtitle": "What we were doing"
  },
  "Contact": {
    "Address": {
      "Text": "Jakabova 42<br/>\nBratislava, 82104<br/>\nSlovak republic",
      "Title": "Address"
    },
    "CompanyInfo": {
      "Text": "Narative (Naradesign s.r.o.)<br/>\nICO: 46082824<br/>\nDIC: 2023224335",
      "Title": "Company details"
    },
    "Contact": {
      "Text": "<a href=\"mailto:info@narative.sk\" title=\"info@narative.sk\">info@narative.sk</a><br/>\n<a href='tel:+421918040888'>+421 918 040 888</a>",
      "Title": "Contact"
    },
    "Email": "E-mail",
    "ErrorMessages": {
      "EmptyMessage": "The message must not be empty",
      "NoEmailOrTel": "You need to fill in the e-mail or telephone number",
      "Success": "Message has been sent. We will reach to you soon."
    },
    "FillOutForm": "Fill the brief",
    "Gdpr": {
      "1": "I agree with",
      "2": "the processing of personal data"
    },
    "HowDidYouHearAboutUs": 'How did you hear about us?',
    "NameAndSurname": "Name and surname",
    "ServiceOptions": {
      "Applications": "Applications",
      "ApplicationsAndroid": "Android",
      "ApplicationsIos": "iOS",
      "ApplicationsMultiPlatform": "Cross-platform",
      "Graphic": "Graphics",
      "GraphicDigitalPrint": "Digital/Print",
      "GraphicLogoBranding": "Logo/Branding",
      "OtherServices": "Other services",
      "OtherServicesMarketingSEO": "Marketing/SEO",
      "OtherServicesOther": "Other",
      "OtherServicesProgramming": "Programming",
      "Webpages": "Websites",
      "WebpagesCRM": "ERP/CRM",
      "WebpagesCorporate": "Corporate",
      "WebpagesEshop": "E-shops",
      "WebpagesMicrosite": "Microsite",
      "WebpagesPortal": "Portals"
    },
    "ServicePlaceholder": "Which service are you interested in?",
    "Submit": "Send message",
    "TelNumber": "Telephone number",
    "Title": "Contact us",
    "YourMessage": "Your message"
  },
  "Footer": {
    "EmailPlaceholder": "Your e-mail",
    "Gdpr1": "I agree with",
    "Gdpr2": "the processing of personal data",
    "NewsLetter": "Get the latest articles, news and others directly to your e-mail."
  },
  "Homepage": {
    "FeaturedBlogPosts": {
      "AllPosts": "Check out all the articles",
      "Title": "From our blog"
    },
    "FeaturedCaseStudies": {
      "OpenBtn": "Look into the case study",
      "showAll": "Všetky referencie",
      "selectedProjects": "Vyberte projekt",
      "web-eshop": "Profesionálne moderné e-shopy na mieru so všetkými funkciami, ktoré potrebujete",
      "app-multi-platform": "Náročné, funkčné a používateľský prívetivé portálové riešenia",
      "web-portal": "Funkčné a intuitívne navrhnuté portály premyslené do posledného detailu",
      "graphic-logo-branding": "Vybudujte si známu značku, s ktorou sa vaši zákazníci budú vedieť stotožniť",
      "web-corporate": "Prezentujte svoj business na úrovni a získajte viac nových klientov",
      "web-microsite": "Náročné, funkčné a používateľský prívetivé portálové riešenia",
      "web-crm": "Náročné, funkčné a používateľský prívetivé portálové riešenia",
      "app-android": "Nikdy nebolo jednoduchšie vytvoriť si modernú mobilnú aplikáciu",
      "app-ios": "Nikdy nebolo jednoduchšie vytvoriť si modernú mobilnú aplikáciu",
    },
    "FeaturedLogos": {
      "AllClients": "Show all clients",
      "Title": "We work for prestigious companies"
    },
    "Landing": {
      "Badge": "New",
      "Contact": "Contact us",
      "OurWork": "See our works"
    },
    "SaidAboutUs": {
      "AllReferences": "Read all the references",
      "Title": "They said about us"
    }
  },
  "HowWeWork": {
    "Menu": "How we work",
    "ReferencesBtn": "All references",
    "SectionSelect": "Choose section",
    "Subtitle": "See our projects"
  },
  "UxPrincipes": {
    "Menu": "UX princípy",
  },
  "Misc": {
    "Categories": {
      "app-android": "Android",
      "app-ios": "iOS",
      "app-multi-platform": "Multi-platform",
      "graphic-digital/print": "Digital/Print",
      "graphic-logo-branding": "Logo/Branding",
      "web-corporate": "Corporate",
      "web-crm": "ERP/CRM",
      "web-eshop": "E-shops",
      "web-microsite": "Microsite",
      "web-portal": "Portals"
    },
    "Inflected": {
      "app-android": "Android",
      "app-ios": "iOS",
      "app-multi-platform": "Multi-platform",
      "graphic-digital/print": "Digital/Print",
      "graphic-logo-branding": "Logo/Branding",
      "web-corporate": "Corporate",
      "web-crm": "ERP/CRM",
      "web-eshop": "E-shops",
      "web-microsite": "Microsite",
      "web-portal": "Portals"
    },
  },
  "Navigation": {
    "aboutUs": "About Us",
    "blog": "Blog",
    "contact": "Contact",
    "brief": "Váš projekt",
    "ourWork": "Our works",
    "services": "Services",
  },
  "OurServices": {
    "AllReferences": "All references",
    "Menu": "Our services",
    "OpenBtn": "Find out more",
    "SectionSelect": "Choose section",
    "Subtitle": "See our projects"
  },
  "OurWork": {
    "AllReferences": "All references",
    "LogosTitle": "We worked also with...",
    "Menu": "Clients",
    "SectionSelect": "Choose section",
    "Substitle": "See our projects"
  },
  "References": {
    "ApplicationsTitle": "Applications",
    "Contact": {
      "ContactUs": "Write to us",
      "FillOutForm": "Fill the brief",
      "Subtitle": "Are you interested in us?",
      "Title": "Contact us"
    },
    "ContactUs": "Write to us",
    "FeaturedTitle": "Featured projects",
    "GraphicTitle": "Graphics",
    "Menu": {
      "Applications": "Applications",
      "Featured": "Featured",
      "Graphic": "Graphics",
      "Webpages": "Websites"
    },
    "MoreProjects": {
      "Applications": "Applications",
      "Featured": "Featured projects",
      "Graphic": "Graphics",
      "OpenBtn": "Show",
      "Title": "Next project",
      "Webpages": "Websites"
    },
    "OpenCaseStudyBtn": "Look into the case study",
    "SectionSelect": "Choose section",
    "WebpagesTitle": "Websites"
  },
  "ServiceDetail": {
    "AllReferences": "All references",
    "Subtitle": "See our projects"
  },
  "Services": {
    "Programming": {
      "Title": "Programming",
      "Menu": "Programming",
    },
    "Design": {
      "Title": "Design",
      "Menu": "Design",
    },
  },
  "cookieBar": {
    "Text1": "Tento web používa na poskytovanie služieb a analýzu návštevnosti súbory cookie. Používaním tohto webu súhlasíte <wbr /> so ",
    "Text2": "spracovaním osobných údajov",
    "BtnSubmit": "Súhlasím a pokračovať",
    "BtnEdit": "Spravovať",
    "Cancel": "Zrušiť",
    "Save": "Uložiť",
    "NesesseryTitle": "NUTNÉ",
    "NesesseryText": "<b>FUNKČNÉ COOKIES</b> - Technické uloženie alebo prístup sú nevyhnutne potrebné na legitímny účel umožnenia použitia konkrétnej služby, ktorú si účastník alebo používateľ výslovne vyžiadal, alebo na jediný účel vykonania prenosu komunikácie cez elektronickú komunikačnú sieť.",
    "OptionalTitle": "VOLITEĽNÉ",
    "OptionalText": "<b>ŠTATISTICKÉ & MARKETINGOVÉ</b> - Technické úložisko alebo prístup, ktorý sa používa výlučne na štatistické účely + Technické úložisko alebo prístup sú potrebné na vytvorenie používateľských profilov na odosielanie reklamy alebo sledovanie používateľa na webovej stránke alebo na viacerých webových stránkach na podobné marketingové účely."
  }
};

export default lang