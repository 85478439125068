import React from 'react'

const TelephoneNumber = () => {
  return (
    <span className={'phone-number'} itemType="https://schema.org/PostalAddress">
      <img src='/images/phone_23px.png' alt='phone icon'/>
      <a itemProp="telephone" href="tel:+421918040888">0918 040 888</a>
    </span>
  )
}

export default TelephoneNumber;

