const lang =  {
  "404": {
    "BackBtn": "Späť na úvodnu stránku",
    "Img": "https://i.imgur.com/j51uHm1.gif",
    "Title": "Stránka, ktorú hľadáte neexistuje."
  },
  "Blog": {
    "AllArticles": "Všetky články",
    "MoreBtn": "Pozrite si viac",
    "SectionSelect": "Vyberte sekciu",
    "Title": "Píšeme o svete webov.",
    "Description": "description"
  },
  "BlogDetail": {
    "FacebookShare": "Zdieľať na facebooku",
    "MoreBtn": "Zobraz viac",
    "TwitterShare": "Zdieľať na Twitter"
  },
  "CaseStudyDetail": {
    "ContactSubtitle": "Zaujali sme Vás?",
    "ContactTitle": "Kontaktujte nás",
    "ContactUsBtn": "Napíšte nám",
    "FillOutForm": "Vyplňte dotazník",
    "GalleryShowLess": "Zobraziť menej",
    "GalleryShowMore": "Zobraziť ďalšie",
    "NextCategoryOpenBtn": "Zobraz",
    "NextCategoryTitle": "Ďalší projekt",
    "Subtitle": "Čo sme robili"
  },
  "Contact": {
    "Address": {
      "Text": "Jakabova 42<br/>\nBratislava, 82104<br/>\nSlovenská republika",
      "Title": "Adresa"
    },
    "CompanyInfo": {
      "Text": "Narative (Naradesign s.r.o.)<br/>\nIČO: 46082824<br/>\nDIČ: 2023224335",
      "Title": "Firemné údaje"
    },
    "Contact": {
      "Text": "<a href=\"mailto:info@narative.sk\" title=\"info@narative.sk\">info@narative.sk</a><br/>\n<a href='tel:+421918040888'>+421 918 040 888</a>",
      "Title": "Kontakt"
    },
    "Email": "E-mail",
    "ErrorMessages": {
      "EmptyMessage": "Správa nesmie byť prázdna",
      "NoEmailOrTel": "Je nutne vyplniť e-mail alebo telefónne číslo",
      "Success": "Správa odoslaná. Budeme Vás čoskoro kontaktovať."
    },
    "FillOutForm": "Vyplňte dotazník",
    "Gdpr": {
      "1": "Súhlasím so",
      "2": "spracovaním osobných údajov"
    },
    "HowDidYouHearAboutUs": 'Odkial ste sa o nas dozvedeli?',
    "NameAndSurname": "Meno a priezvisko",
    "ServiceOptions": {
      "Applications": "Aplikácia",
      "ApplicationsAndroid": "Android",
      "ApplicationsIos": "iOS",
      "ApplicationsMultiPlatform": "Cross-platform",
      "Graphic": "Digital",
      "GraphicDigitalPrint": "Grafika/Bannery",
      "GraphicLogoBranding": "Logo/Branding",
      "OtherServices": "Ďalšie služby",
      "OtherServicesMarketingSEO": "Marketing/SEO",
      "OtherServicesOther": "Iné",
      "OtherServicesProgramming": "Programovanie",
      "OtherServicesServis": "Pravidelný servis",
      "Webpages": "Webstránka",
      "WebpagesCRM": "ERP/CRM",
      "WebpagesCorporate": "Firemný web",
      "WebpagesEshop": "E-shop",
      "WebpagesMicrosite": "Microsite",
      "WebpagesPortal": "Portál"
    },
    "ServicePlaceholder": "O akú službu máte záujem",
    "Submit": "Pošlite správu",
    "TelNumber": "Telefónne číslo",
    "Title": "Ozvite sa nám",
    "YourMessage": "Vaša správa"
  },
  "Footer": {
    "EmailPlaceholder": "Váš e-mail",
    "Gdpr1": "Súhlasím so",
    "Gdpr2": "spracovaním osobných údajov",
    "NewsLetter": "Dostávajte novinky priamo do vášho e-mailu."
  },
  "Homepage": {
    "FeaturedBlogPosts": {
      "AllPosts": "Čítajte všetky články",
      "Title": "Z nášho blogu"
    },
    "FeaturedCaseStudies": {
      "OpenBtn": "Otvorte štúdiu",
      "showAll": "Všetky",
      "selectedProjects": "Vyberte projekt",
      "web-eshop": "Moderné e-shopy na mieru so všetkými funkciami, ktoré potrebujete",
      "web-portal": "Funkčné a intuitívne navrhnuté portály premyslené do posledného detailu",
      "graphic-logo-branding": "Vybudujte si známu značku, s ktorou sa vaši zákazníci budú vedieť stotožniť",
      "web-corporate": "Prezentujte svoj business na najvyššej úrovni a získajte viac nových klientov",
      "web-microsite": "Náročné, funkčné a používateľský prívetivé portálové riešenia",
      "web-crm": "Náročné, funkčné a používateľský prívetivé portálové riešenia",
      "app-android": "Nikdy nebolo jednoduchšie vytvoriť si modernú mobilnú aplikáciu",
      "app-ios": "Nikdy nebolo jednoduchšie vytvoriť si modernú mobilnú aplikáciu",
      "app-multi-platform": "Nikdy nebolo jednoduchšie vytvoriť si modernú mobilnú aplikáciu",
    },
    "FeaturedLogos": {
      "AllClients": "Prezrite si viac klientov",
      "Title": "Dôverujú nám aj veľkí klienti"
    },
    "Landing": {
      "Badge": "Nové",
      "Contact": "Pozrite si práce",
      "OurWork": "Chcem e-shop"
    },
    "SaidAboutUs": {
      "AllReferences": "Prezrite si viac klientov",
      "Title": "Povedali o nás"
    }
  },
  "HowWeWork": {
    "Menu": "Ako pracujeme",
    "ReferencesBtn": "Všetky referencie",
    "SectionSelect": "Vyberte sekciu",
    "Subtitle": "Pozrite si naše projekty"
  },
  "Misc": {
    "Categories": {
      "app-android": "Appky",
      "app-ios": "Appky",
      "app-multi-platform": "Appky",
      "graphic-digital/print": "Grafika/Bannery",
      "graphic-logo-branding": "Branding",
      "web-corporate": "Firemné",
      "web-crm": "ERP/CRM",
      "web-eshop": "E-shopy",
      "web-microsite": "Microsite",
      "web-portal": "Portály"
    },
    "Inflected": {
      "app-android": "appky",
      "app-ios": "appky",
      "app-multi-platform": " appky",
      "graphic-digital/print": "grafiky",
      "graphic-logo-branding": "brandingy",
      "web-corporate": "weby",
      "web-crm": "CRM-ká",
      "web-eshop": "eshopy",
      "web-microsite": "microsites",
      "web-portal": "portály"
    },
    "Inflected_2": {
      "app-android": "appky",
      "app-ios": "appky",
      "app-multi-platform": " appky",
      "graphic-digital/print": "grafiky",
      "graphic-logo-branding": "brandingy",
      "web-corporate": "firemné weby",
      "web-crm": "CRM-ká",
      "web-eshop": "eshopy",
      "web-microsite": "microsites",
      "web-portal": "portály"
    },
  },
  "Navigation": {
    "aboutUs": "O nás",
    "blog": "Blog",
    "contact": "Napíšte nám",
    "brief": "Váš projekt",
    "ourWork": "Naše práce",
    "services": "Služby",
  },
  "OurServices": {
    "AllReferences": "Všetky referencie",
    "Menu": "Kto sme",
    "OpenBtn": "Zistite viac",
    "SectionSelect": "Vyberte sekciu",
    "Subtitle": "Pozrite si naše projekty",
    "Process": "Ako pracujeme",
    "ProcessSubTitle": "Pozrite si náš postup"
  },
  "OurWork": {
    "AllReferences": "Všetky referencie",
    "LogosTitle": "Spolupracovali sme aj s...",
    "Menu": "Naši klienti",
    "SectionSelect": "Vyberte sekciu",
    "Substitle": "Pozrite si naše projekty"
  },
  "UxPrincipes": {
    "Menu": "UX princípy",
  },
  "References": {
    "ApplicationsTitle": "Aplikácie",
    "Contact": {
      "ContactUs": "Napíšte nám",
      "FillOutForm": "Vyplňte dotazník",
      "Subtitle": "Zaujali sme Vás?",
      "Title": "Kontaktujte nás",
    },
    "ContactUs": "Napíšte nám",
    "FeaturedTitle": "Vybrané projekty",
    "GraphicTitle": "Digital",
    "Menu": {
      "Applications": "Aplikácie",
      "Featured": "Vybrané",
      "Graphic": "Digital",
      "Webpages": "Webstránky"
    },
    "MoreProjects": {
      "Applications": "Aplikácie",
      "Featured": "Vybrané projekty",
      "Graphic": "Digital",
      "OpenBtn": "Zobraz",
      "Title": "Ďalšía kategória",
      "Webpages": "Webstránky"
    },
    "OpenCaseStudyBtn": "Otvorte štúdiu",
    "SectionSelect": "Vyberte sekciu",
    "WebpagesTitle": "Webstránky"
  },
  "ServiceDetail": {
    "AllReferences": "Pozrite si naše projekty",
    "Subtitle": "Naše referencie",
    "Process": "Ako pracujeme",
    "ProcessSubTitle": "Pozrite si náš postup"
  },
  "Services": {
    "Programming": {
      "Title": "Programovanie",
      "Menu": "Programovanie",
    },
    "Design": {
      "Title": "Dizajn",
      "Menu": "Dizajn",
    }
  },
  "cookieBar": {
    "Text1": "Tento web používa na poskytovanie služieb a analýzu návštevnosti súbory cookie. Používaním tohto webu súhlasíte <wbr /> so ",
    "Text2": "spracovaním osobných údajov",
    "BtnSubmit": "Súhlasím a pokračovať",
    "BtnEdit": "Spravovať",
    "Cancel": "Zrušiť",
    "Save": "Uložiť",
    "NesesseryTitle": "NUTNÉ",
    "NesesseryText": "<b>FUNKČNÉ COOKIES</b> - Technické uloženie alebo prístup sú nevyhnutne potrebné na legitímny účel umožnenia použitia konkrétnej služby, ktorú si účastník alebo používateľ výslovne vyžiadal, alebo na jediný účel vykonania prenosu komunikácie cez elektronickú komunikačnú sieť.",
    "OptionalTitle": "VOLITEĽNÉ",
    "OptionalText": "<b>ŠTATISTICKÉ & MARKETINGOVÉ</b> - Technické úložisko alebo prístup, ktorý sa používa výlučne na štatistické účely + Technické úložisko alebo prístup sú potrebné na vytvorenie používateľských profilov na odosielanie reklamy alebo sledovanie používateľa na webovej stránke alebo na viacerých webových stránkach na podobné marketingové účely."
  }
};

export default lang