import React, { Component } from 'react'
import Helmet from 'react-helmet'
import * as PropTypes from 'prop-types'
import { removeHtml } from '../utilities'


class SeoHelmet extends Component {
  render() {
    const {
      title,
      description,
      keywords,
      image,
      url,
      noIndex
    } = this.props
    return (
      <Helmet htmlAttributes={{ lang: 'sk' }}>
        <script type="application/ld+json">
          {`
            {
              "@context":"http://schema.org",
              "@type":"BreadcrumbList",
              "itemListElement":[
                {
                  "@type":"ListItem",
                  "position":1,
                  "name": "Eshopy",
                  "item":"https://narative.sk/nase-prace#eshop"
                },
                {
                  "@type":"ListItem",
                  "position":2,
                  "name": "Nase prace",
                  "item":"https://narative.sk/nase-prace"
                },
                {
                  "@type":"ListItem",
                  "position":3,
                  "name": "Sluzby",
                  "item":"https://narative.sk/sluzby"
                },
                {
                  "@type":"ListItem",
                  "position":4,
                  "name": "Vyplnte dotaznik",
                  "item":"https://narative.sk/dotaznik"
                },
                {
                  "@type":"ListItem",
                  "position":5,
                  "name": "Kontakt",
                  "item":"https://narative.sk/kontakt"
                }
              ]
            }
          `}
        </script>

        {noIndex &&
          <meta name="robots" content="noindex, nofollow" />
        }
        {url && <link rel="canonical" href={url} />
        }

        {
          title && [
            <title key={1}>{removeHtml(title)}</title>,
            <meta property="og:title" content={removeHtml(title)} key={2} />,
          ]}

        {
          description && [
            <meta name="description" content={description} key={3} />,
            <meta property="og:description" content={description} key={4} />,
          ]}

        {
          keywords && <meta name="keywords" content={keywords.join(', ')} key={5} />
        }

        {
          image && <meta property="og:image" content={image} key={6} />
        }

        {
          url && <meta property="og:url" content={url} key={7} />
        }
      </Helmet>
    )
  }
}

SeoHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string,
}

SeoHelmet.defaultProps = {
  noIndex: false,
  url: ''
}



export default SeoHelmet