/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/mixins.scss"


const ReactDOM = require('react-dom')

let position = '';

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

const doStuff = ({ location, pageContext, data }) => {
  if (position !== 0) {
    localStorage.setItem("scroll_last_position", JSON.stringify({ pathname: location.pathname, position}));
  }
}

export const wrapPageElement = ({ element, location, props }) => {
    doStuff({ location, ...props});
    return element
}

const onScroll = () => {
    if (typeof window != 'undefined') {
    position = document.body.getBoundingClientRect().top * - 1
  }
}

document.addEventListener('scroll', onScroll)